import React from 'react'
import alux from '../../../images/col/samples/mod/183_27_alux.jpg'
import smooth_grey from '../../../images/col/samples/mod/651_07_smooth_grey.jpg'
import signal_grey_sm from '../../../images/col/samples/mod/625_92_signal_grey_sm.jpg'
import basalt_grey_sm from '../../../images/col/samples/mod/628_08_basalt_grey_sm.jpg'

function Moderni_Col() {

    return(

        <div className="w3-padding w3-mobile"  style={{display: "block"}}>

            <table className="w3-table w3-bordered">
                <tr>
                    <th>Цвят</th>
                    <th>Име</th>
                </tr>
                <tr>
                    <td><img src={alux} className="w3-mobile"></img></td>
                    <td>DEC.183/27 Alux DB 703</td>
                </tr>
                <tr>
                    <td><img src={smooth_grey} className="w3-mobile"></img></td>
                    <td>DEC.651/07 Сиво гладко</td>
                </tr>
                <tr>
                    <td><img src={signal_grey_sm} className="w3-mobile"></img></td>
                    <td>DEC.625/92 Сигнално сиво гладко</td>
                </tr>
                <tr>
                    <td><img src={basalt_grey_sm} className="w3-mobile"></img></td>
                    <td>DEC.628/08 Базалтово сиво гладко</td>
                </tr>
            </table>

        </div>
    )
}

export default Moderni_Col