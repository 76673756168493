// list of quick access links on the main page

import React, { Component } from 'react';
import QuickLink from './QuickLink';

import pic_icon from '../images/icons/pic_icon.png'
import vid_icon from '../images/icons/vid_icon.png'
import down_icon from '../images/icons/down_icon.png'

class LinkList extends Component{
    constructor(props) {
        super(props);
        this.state = {
          linkPathList: [
            {text: "Каталози", textSmall: "За изтегляне", href: "/downloads", imgSrc: down_icon},
            {text: "Галерия", textSmall: "Видео", href: "/video", imgSrc: vid_icon},
            {text: "Галерия", textSmall: "Снимки", href: "/gallery", imgSrc: pic_icon}
          ]
        };
    }

    renderQuickLink(imgSrc, text, textSmall, href) {
        return (
            <QuickLink imgSrc={imgSrc} text={text} textSmall={textSmall} href={href} key={'link_to_' + href}/>
        )
    }

    render() {
        const links = []
        for(const link of this.state.linkPathList)
            links.push(this.renderQuickLink(link.imgSrc, link.text, link.textSmall, link.href))

        return (
            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <h2 className="w3-padding">Бързи линкове</h2>
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    {links}
                </ul>
            </div>
        )
    }
}

export default LinkList;