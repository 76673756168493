import React from 'react'
import '../App.css'
import QuickLink from '../components/QuickLink'
import pdf_icon from '../images/icons/pdf_icon.png'
import Accordion from '../components/Accordion'
import RestrictedCats from './text/restricted_cats'

function Downloads() {

  const items = [
    {
      text: "Каталози със специален достъп",
      content: RestrictedCats
    }
  ]

  return (
    <div className="w3-padding">
      <h1>За изтегляне</h1>
      <div className="w3-padding w3-margin-top w3-margin-bottom">
        <ul className="w3-ul w3-card-4 w3-hoverable">
          <QuickLink href="/files/free_access_cats/Elegante 5102 LP2 крило врата Eforte 17157 HLE584 и 15010 GS30.pdf" download={true} text="Elegante 5102 LP2 крило врата" textSmall="Eforte 17157 HLE584 и 15010 GS30" imgSrc={pdf_icon}></QuickLink>
        </ul>
      </div>

      <div className="w3-padding w3-margin-top w3-margin-bottom">
        <ul className="w3-ul w3-card-4 w3-hoverable">
          <QuickLink href="/files/free_access_cats/Elegante Технология на лепене на стъклопакет.pdf" download={true} text="Elegante" textSmall="Технология на лепене на стъклопакет" imgSrc={pdf_icon}></QuickLink>
        </ul>
      </div>

      <div className="w3-padding w3-margin-top w3-margin-bottom">
        <ul className="w3-ul w3-card-4 w3-hoverable">
          <QuickLink href="/files/free_access_cats/Водобран 367 + Arcade 14849.pdf" download={true} text="Водобран 367" textSmall="+ Arcade 14849" imgSrc={pdf_icon}></QuickLink>
        </ul>
      </div>

      <div className="w3-padding w3-margin-top w3-margin-bottom">
        <ul className="w3-ul w3-card-4 w3-hoverable">
          <QuickLink href="/files/free_access_cats/Макс размери Аркаде 127212_112.pdf" download={true} text="Аркаде" textSmall="Макс размери" imgSrc={pdf_icon}></QuickLink>
        </ul>
      </div>

      <div className="w3-padding w3-margin-top w3-margin-bottom">
        <ul className="w3-ul w3-card-4 w3-hoverable">
          <QuickLink href="/files/free_access_cats/Обков GU047054 за HS476.pdf" download={true} text="Обков GU047054" textSmall="за HS476" imgSrc={pdf_icon}></QuickLink>
        </ul>
      </div>

      <div className="w3-padding w3-margin-top w3-margin-bottom">
        <ul className="w3-ul w3-card-4 w3-hoverable">
          <QuickLink href="/files/free_access_cats/Представяне усилен ъгъл за врата EH184.pdf" download={true} text="Представяне усилен ъгъл" textSmall="за врата EH184" imgSrc={pdf_icon}></QuickLink>
        </ul>
      </div>

      <div className="w3-padding w3-margin-top w3-margin-bottom">
        <ul className="w3-ul w3-card-4 w3-hoverable">
          <QuickLink href="/files/free_access_cats/Съкратен преглед Arcade.pdf" download={true} text="Arcade" textSmall="Съкратен преглед" imgSrc={pdf_icon}></QuickLink>
        </ul>
      </div>

      <div className="w3-padding w3-margin-top w3-margin-bottom">
        <ul className="w3-ul w3-card-4 w3-hoverable">
          <QuickLink href="/files/free_access_cats/Съкратен преглед Eforte.pdf" download={true} text="Eforte" textSmall="Съкратен преглед" imgSrc={pdf_icon}></QuickLink>
        </ul>
      </div>

      <div className="w3-padding w3-margin-top w3-margin-bottom">
        <ul className="w3-ul w3-card-4 w3-hoverable">
          <QuickLink href="/files/free_access_cats/Съкратен преглед Tecnocor 2.pdf" download={true} text="Elegante" textSmall="Съкратен преглед" imgSrc={pdf_icon}></QuickLink>
        </ul>
      </div>

      <hr></hr>

      <div className="w3-row-padding">
        <Accordion sectionArr={items}></Accordion>
        <p className="w3-padding"><i>Моля, свържете се с Михаил Иванов, за да получите достъп</i></p>
      </div>
    </div>
  )
}

export default Downloads