import React from 'react'

function About() {
    return (
      <div className="w3-padding">
          <h1>За нас</h1>
          <div>
            <p>„ТРЕБЕЛ 16“ ООД е водещ дистрибутор в България за изделията на 
              <a href="http://www.deceuninck.com/">„Декьонинк Груп“</a>. 
              „Декьонинк“ е световен лидер на пазара на PVC
              профили и системи, който обхваща 91 страни в Европа, Северна Америка и Азия.
              Продуктовото портфолио на „Требел 16“ включва енергоспестяващи PVC профили и
              иновативни конструктивни решения за прозорци и врати, облицовки за фасади и покриви,
              настилки за тераси и плътни огради, допълнителни профили и др.</p>
            <p>Наред с отличното качество, системите „Декьонинк“ имат изключително висока енергийна
              ефективност, позволяваща изграждането на устойчиви сгради.
              „Декьонинк“ откликва на съвременните потребности от дълготрайни, елегантни и щадящи
              природата продукти чрез своя затворен цикъл на производството – в края на живота им, след
              употреба от над 50 години, продуктите „Декьонинк“ могат да бъдат рециклирани отново като
              суровини.</p>
            <p>В складовите бази на „Требел 16“ в градовете Пловдив и Берковица поддържаме голямо
              разнообразие и достатъчни количества от складови наличности.
              „Требел 16“ разполага със собствен транспорт за доставки до производителите на PVC-
              дограма. Това ни позволява да достигнем в срок до всяка производствена база в България, като
              спестяваме на нашите клиенти усилия, време и средства.</p>
          </div>               
      </div>
    );
  }
  
  export default About;