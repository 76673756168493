import React from 'react'
import eforte_kap from '../../images/proz/eforte_kap.jpg'
import eforte_polu_crop from '../../images/proz/eforte_polu_crop.jpg'
import eforte_pravo from '../../images/proz/eforte_pravo.jpg'
import eforte_toplo from '../../images/proz/eforte_toplo.jpg'
import eforte_alum from '../../images/proz/eforte_alum.jpg'
import eforte_alum2_resize from '../../images/proz/eforte_alum2_resize.jpg'
import QuickLink from '../../components/QuickLink'
import col_icon from '../../images/icons/col_icon.png'

function Proz_Eforte() {

    return (
        <div>
            <h1 className="w3-padding">ПРОФИЛ ЗА ПРОЗОРЦИ EFORTE</h1>
            <h2 className="w3-padding">Безкомпромисно добре изработена система за пестене на енергия</h2>
            <div className="w3-cell-row">
                <img src={eforte_alum} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <p className="w3-cell w3-padding w3-mobile">
                    <p>С PVC профилната система Eforte обръщаме особено внимание на топлоизолацията на дома или офиса за ефективно пестене на 
                    енергия. С дълбочина на профила от 84 мм, конвенционална стоманена армировка и без допълнителни изолационни компоненти 
                    и аксесоари системата Eforte постига уникална Uf стойност от 0,95 W/m2K. В допълнение към обикновените 
                    уплътнения има още едно иновативно уплътнение, разположено във фалца на рамката, което допринася за още по-добрата 
                    изолация.</p>
                    <p>Дебелината на стъклопакета, двоен или троен, може да стигне до 56 мм, ако трябва да е пригодена за специално защитно 
                    стъкло или шумова изолация. Широка гама от цветове и декори позволява всички желания по отношение на дизайна да бъдат 
                    изпълнени.</p>
                    <p>Елегантният дизайн на PVC профила Eforte го прави подходящ за монтаж както в стари, така и в новопостроени сгради.</p> 
                    <p>Разбира се, можете да откриете и <a href="/vrati">подходящата PVC входна врата</a> за вашите прозорци Eforte.</p>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <div className="w3-cell-middle w3-margin-top w3-padding w3-mobile">
                    <img src={eforte_kap} className="w3-mobile"/> 
                    <p className="w3-margin-top">Ефорте с алуминиева капачка</p>
                </div>
                <div className="w3-cell w3-margin-left w3-padding w3-mobile">
                    <h2 className="w3-margin-left">Технически спецификации</h2>      
                    <ul>
                        <li>84 мм дълбочина на профила, 6-камерен</li>
                        <li>Коефициент на топлопреминаване на целия прозорец Uw до 0,64 W/m2K</li>
                        <li>Коефициент на топлопреминаване на рамката Uf = 0,95 W/m2K</li>
                        <li>Изпълнен стандарт за пасивна къща с нормален троен стъклопакет</li>
                        <li>Сертификат IFT Rosenheim за пригодност в пасивна къща</li>
                        <li>Стъклопакети с дебелина до 56 мм за много добра топло- и шумоизолация</li>
                        <li>Две разновидности на дизайна на крилото</li>
                        <li>Ново допълнително уплътнение, разположено във фалца на рамката, за още по-добра топлоизолация</li>
                        <li>Вътрешен двустъпков стъклодържач за по-добра охранителна функция</li>
                        <li>Височина на фалца на стъклото от 25 мм за предотвратяване на събирането на кондензна вода</li>
                        <li>Специална технология за лепене – бондинг, позволяваща височина на прозоречните елементи от пода до тавана до 2,6 метра</li>
                        <li>Шумоизолация до 47 dB (клас на шумозащита 5)</li>
                        <li>Елегантен профил с тесни видими контури от 120 мм и 20-градусови наклони за добро оттичане на водата</li>
                        <li>40 различни цвята за декориране с фолио, както и богат избор на цветове с алуминиева облицовка</li>
                    </ul>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">   
                <div className="w3-cell w3-padding w3-mobile"> 
                    <img src={eforte_polu_crop} className="w3-mobile"/> 
                    <p>Ефорте с полуизпъкнало крило</p>
                </div>
                <div className="w3-cell w3-padding w3-mobile"> 
                    <img src={eforte_pravo} className="w3-mobile"/> 
                    <p>Ефорте с право крило</p>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={eforte_toplo} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-padding w3-mobile">
                    <h2>Перфектна топлоизолация за максимално пестене на енергия</h2>
                    <p>Енергийно-ефективният PVC прозорец Eforte с дълбочина на профила от 84 мм и 6 камери постига отлична Uf стойност от 
                    0,95 W/m2K. Поради тази причина с лекота покрива изискванията на Регламента за пестене на енергия.</p>
                    <p>Стандартът за пасивна къща (Uw ≤ 0,8 W/m2K) може да бъде приложен с много добра ефективност на разходите със стандартен 
                    троен стъклопакет. Eforte е единствената система с конвенционална стоманена армировка и без допълнителни изолационни 
                    елементи, която е сертифицирана от международно признатия Институт за прозоречна техника като пригодна за използване в 
                    пасивна къща.</p>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={eforte_alum2_resize} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-mobile">
                    <h2>Прозорец Eforte с алуминиево покритие</h2>
                    <p>Визията на истинска алуминиева дограма се постига посредством алуминиева капачка с челно съединени ъгли, без да се губят 
                    енергийно-ефективните качества на PVC прозореца. Алуминиевата облицовка може да бъде изработена почти във всякакъв цвят 
                    според Вашите  предпочитания.</p>
                    <p>Като алтернатива може да се използват иновативни декориращи фолиа, които да придадат визията, наподобяваща алуминиева дограма. 
                    В комбинация с профилите цвят антрацит, Вашите PVC прозорци изглеждат досущ като алуминиеви и се вписват чудесно към архитектоничното 
                    съчетание на структурните елементи на Вашия недвижим имот.</p>

                </p>
            </div>

            <hr></hr>

            <div>
                <p className="w3-padding">
                    <h2>Богат избор на цветове за индивидуално оформление</h2>
                    Можете да избирате между 40 различни декора. Палитрата обхваща универсални цветове, декорации с вид на дърво, повърхности с перлена структура или с вид на алуминий. 
                    Цветовете са групирани по архитектурни стилове, за да отговорят на всеки индивидуален вкус.
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за цветовата програма на Декьонинк" imgSrc={col_icon} href="/col_pvc" />
                </ul>
            </div>

        </div>
    );
  }
  
  export default Proz_Eforte;