import React from 'react'
import QuickLink from '../../components/QuickLink'
import Accordion from '../../components/Accordion'
import Moderni from './text/moderni'
import Architecturni from './text/architecturni'
import Classic from './text/classic'
import Vechni from './text/vechni'
import Traditional from './text/traditional'
import col_icon from '../../images/icons/col_icon.png'


function ColPVC() {

  const accItems = [
    {
      text: "Модерни цветове",
      content: Moderni
    },
    {
      text: "Архитектурни цветове",
      content: Architecturni
    },
    {
      text: "Класически цветове",
      content: Classic
    },
    {
      text: "Вечни цветове",
      content: Vechni
    },
    {
      text: "Традиционни цветове",
      content: Traditional
    }
  ]

  return (
    <div>
      <h1 className="w3-padding">Цветова програма на Декьонинк</h1>

      <div className="w3-padding">
        <h3>Индивидуални цветове за всеки вкус</h3>
        <p>Богатата цветова палитра на PVC-профилите предлага почти неограничени възможности за дизайнерски решения и индивидуална визия на 
        всяка нова или реновирана фасада.</p>
        <p>Цветовете са групирани по архитектурни стилове, за да отговорят на всеки индивидуален вкус.</p>
      </div>

      <Accordion sectionArr={accItems}></Accordion>         

      <div className="w3-row-padding w3-margin-top w3-margin-bottom">
        <ul className="w3-ul w3-card-4 w3-hoverable">
            <QuickLink text="Обратно" textSmall="към цветове" imgSrc={col_icon} href="/colours" />
        </ul>
      </div> 
    </div>
  );
}

export default ColPVC