import React from 'react'
import ModalBoxBtn from '../../../components/ModalBoxBtn'
import col from '../../../images/col/samples/twinson-pvc/fasadi_pvc.jpg'

function FasadiPVC_Col() {

    return(

        <div className="w3-mobile">
            <img src={col} style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%"}}/>
        </div>
    )
}

function FasadiPVC() {

    return(

        <div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-padding w3-mobile" style={{width: "60%"}}>
                    <p>
                        Можете да избирате между 29 различни цвята на PVC профилите за фасадата на вашата сграда. С красивата и функционална 
                        облицовка тя ще бъде устойчива на атмосферните влияния за дълги години. 
                    </p>
                    <p>    
                        Палитрата включва естествени дървесни 
                        несиметрични шарки, цветни или металически декори. Много от цветовете на фасадните профили са идентични с рамките 
                        на <a href="/col_pvc">прозорците и вратите</a> на Декьонинк за постигане на хомогенна визия. 
                    </p>
                </div>
                <div className="w3-cell w3-padding w3-mobile" style={{verticalAlign: "middle"}}>
                    <ModalBoxBtn text="Разгледайте палитрата" id="fasadiPVC" component={FasadiPVC_Col}/>
                </div>
            </div>
        </div>
    )
}

export default FasadiPVC