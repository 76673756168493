import React from 'react'

function Obkov() {

    return (
      <div className="w3-padding">
        <h1>Обков</h1>         
      </div>
    );
  }
  
  export default Obkov;