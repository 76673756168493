import React from 'react'
import elegante_proz from '../../images/proz/elegante_proz.jpg'
import elegante_proz2 from '../../images/proz/elegante_proz2.jpg'
import elegante_eco from '../../images/proz/elegante_eco.jpg'
import elegante_cross_sec from '../../images/proz/elegante_cross_sec.jpg'
import elegante_mm from '../../images/proz/elegante_mm.jpg'
import elegante_scheme from '../../images/proz/elegante_scheme.jpg'
import QuickLink from '../../components/QuickLink'
import col_icon from '../../images/icons/col_icon.png'

function Proz_Elegante() {

    return (
        <div>
            <h1 className="w3-padding">ПРОФИЛ ЗА ПРОЗОРЦИ ELEGANTE</h1>
            <div className="w3-cell-row">
                <img src={elegante_proz} className="w3-padding w3-margin-top w3-mobile"></img>
                <img src={elegante_proz2} className="w3-padding w3-mobile"></img>
                
                <p className="w3-cell w3-padding w3-mobile">
                    <h2>Изключителна изолация с несравним елегантен дизайн</h2>
                    <p>През есента на 2018 г. Deceuninck представи прозоречния PVC профил с най-тънкия „фалц“ на пазара (само 9 мм) 
                    и така стана пионер в съчетаването на стилния дизайн с висококачествено техническо изпълнение. 
                    Профилът „Elegante“ е резултат от ангажираните инвестиции на фирмата в иновации и дизайн.</p>

                    <p>Elegante от Deceuninck спечели наградата Red Dot 2019 в категорията „Продуктов дизайн“. 
                    Наградата Red Dot съществува от 1954 г. и се смята за една от най-престижните европейски награди за дизайн.</p>

                    <p>Elegante е алтернатива на алуминиевата дограма, но с изключителни стойности на топлоизолация. 
                    Модерният, елегантен дизайн с алуминиев външен вид и изключителни технически характеристики. 
                    Системата е високо екологична, тъй като използва материал „EcoPowerCore“ в сърцевината на профилите. 
                    Изпълнява се в два варианта:  с прави или с изпъкнали крила.</p>

                    <hr></hr>

                    <h2>Технически спецификации</h2>      
                    <ul>
                        <li>Стойност на Uf = 0,93 W / m²K със стандартна стоманена армировка и без допълнителна изолация</li>
                        <li>Дълбочина само 76 мм с остъкляване до 70 мм</li>
                        <li>С дълбочина 76 мм, тази шест-камерна профилна система отговоря на всички потребителски изисквания</li>
                        <li>Правоъгълни остъклителни лайсни за лесно остъкляване</li>
                        <li>Възможност за остъкляване със самозалепващи ленти или с лепило за максимални размери на елементите и защита срещу взлом клас RC2</li>
                        <li>Използване на универсална армировка, отговаряща на различните комбинации от профили</li>
                    </ul>
                    </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={elegante_eco} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-padding w3-mobile">
                    <h2>Устойчивост</h2>
                    <p>Този профил е 100% рециклируем и произведен с рециклирани елементи. Това е двойно рециклиране. 
                    Със срок на експлоатация 50 години и възможността да бъде рециклиран до 10 пъти, без да губи своите характеристики, 
                    същият PVC може да служи като основен материал до 500 години! Deceuninck не се колебае по пътя към устойчив дизайн!</p>
                    <ul>
                        <li>Технология <i>EcoPowerCore</i></li>
                        <li>Ядрото <i>EcoPowerCore</i> е изработено от рециклиран PVC. Стремеж към затворен цикъл на производство</li>
                        <li><i>EcoPowerCore</i> не се вижда, но подобрява механичните свойства на профила</li>
                        <li>Отлична стойност на топлинна пропускливост Uf = 0.93 W / m²K - идеално решение за пасивни и нискоенергийни сгради</li>
                    </ul>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={elegante_cross_sec} className="w3-cell w3-third w3-padding w3-mobile"></img>
                <img src={elegante_mm} className="w3-cell w3-third w3-padding w3-mobile"></img>
                <img src={elegante_scheme} className="w3-cell w3-third w3-padding w3-mobile"></img>
            </div>

            <hr></hr>

            <div>
                <p className="w3-padding">
                    <h2>Богат избор на цветове за индивидуално оформление</h2>
                    <ul>
                        <li>40 различни цветни фолиа</li>
                        <li>Специалните модерни цветове подчертават футуристичния вид на прозорците</li>
                        <li>Сиво профилно ядро за 100% алуминиев вид</li>
                        <li>Голям избор от модерни сиви нюанси</li>
                    </ul>
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за цветовата програма на Декьонинк" imgSrc={col_icon} href="/col_pvc" />
                </ul>
            </div>

        </div>
    )
  }
  
  export default Proz_Elegante;