import React from 'react'
import hebesch_cover_resize from '../../images/povd/hebesch_cover_resize.jpg'
import hebesch_ex from '../../images/povd/hebesch_ex.jpg'
import hebesch_closeup from '../../images/povd/hebesch_closeup.jpg'
import hebesch_cross_sec from '../../images/povd/hebesch_cross_sec_resize.jpg'
import hebesch_view from '../../images/povd/hebesch_view.jpg'
import hebesch_house from '../../images/povd/hebesch_house.jpg'
import QuickLink from '../../components/QuickLink'
import col_icon from '../../images/icons/col_icon.png'


function Povd_Hebeschiebetur() {

    return (
        <div>
            <h1 className="w3-padding">Плъзгане с повдигане Hebeschiebetür</h1>
            <div className="w3-cell-row">
                <img src={hebesch_cover_resize} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <p className="w3-cell w3-padding w3-mobile">
                    <h2>Големите размери създават ново усещане за простор</h2>
                    <p>Плъзгане с повдигане HS 476 на Декьонинк е особено подходяща за врати с големи размери. Отварянето е лесно и удобно 
                    с хоризонтално плъзгащите се крила.</p>
                    <p>Вратите Плъзгане с повдигане имат изключително добра въздухо- и водонепропускливост и покриват стандартите за 
                    топлоизолация с коефициент Uf = 1.3W/m2K. Здравите укрепвания в касата и крилото гарантират високата стабилност на 
                    вратите.</p>
                    <p>Плъзгане с повдигане от Декьонинк е съвместима с всички прозоречни системи - Arcade, Eforte и Elegante.</p>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={hebesch_ex} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-margin-top w3-mobile">
                    <h2>Характеристики</h2>      
                    <ul>
                        <li>76 мм ширина на крилото</li>
                        <li>Съвместима с всички системи на Декьонинк</li>
                        <li>Подходяща за много големи размери (височина до 2,60 м)</li>
                        <li>Лесно отваряне и затваряне</li>
                        <li>Отличен коефициент на топлопреминаване Uf = 1.3W/m2K</li>
                        <li>5 камери за отлична топлоизолация</li>
                        <li>Висок клас устойчивост на вятър и водонепропускливост</li>
                        <li>По-голяма стабилност – стоманени укрепвания в касата и крилото</li>
                        <li>Възможност за стъклопакети до 52 мм</li>
                        <li>По-тясна каса за повече светлина – само 45 мм видима част</li>
                        <li>Може да се инсталира с изцяло заварена рамка</li>
                        <li>Допълнителна средна камера за по-голяма стабилност</li>
                        <li>PVC или алуминиев праг както за стари, така и за нови сгради</li>
                        <li>По-нисък праг &lt; 20 мм</li>
                        <li>Повишена защита от влизане с взлом</li>
                        <li>Налична е във всички 40 цвята на Декьонинк</li>
                    </ul>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">   
                <div className="w3-cell w3-mobile">
                    <img className="w3-padding w3-mobile" src={hebesch_closeup}/> 
                    <img className="w3-padding w3-mobile" src={hebesch_cross_sec}/> 
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={hebesch_view} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-margin-top w3-padding w3-mobile">
                    <h2>Комфортът и удобството са гарантирани</h2> 
                    <p>Вратите Плъзгане с повдигане предлагат максимален комфорт, сигурност и защита от атмосферни влияния. Дори много големи 
                    по размер врати могат лесно да бъдат отваряни и затваряни. Системата Плъзгане с повдигане може да бъде и изцяло 
                    автоматизирана –  отваряне с дистанционно управление</p>
                    <hr></hr>
                </p>
            </div>

            <div className="w3-cell-row w3-padding">
                <h2>Модерна врата Плъзгане с повдигане за по-голям простор</h2>
                <p>Касата с височина от само 45 мм пропуска в дома ви допълнителна светлина. Може да избирате от 40-те различни цветове и 
                декори: имитация на дърво, алуминиев вид или други модерни цветове. Вратата изглежда добре и когато е отворена, 
                защото профилите се фолират не само върху бяла основа, но и върху кафява и антрацит.</p>
            </div>

            <hr></hr>

            <div>
                <p className="w3-padding">
                    <h2>Богат избор на цветове за индивидуално оформление</h2>
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за цветовата програма на Декьонинк" imgSrc={col_icon} href="/col_pvc" />
                </ul>
            </div>

        </div>
    );
}

export default Povd_Hebeschiebetur;