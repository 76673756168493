// quick access link (main page)

import React, { Component } from 'react'
// import pic from '../images/cat2.png'

class QuickLink extends Component{
    constructor(props) {
        super(props);
        this.state = {
          text: props.text,
          textSmall: props.textSmall,
          href: props.href,
          imgSrc: props.imgSrc,
          download: props.download ? props.download : false
        }
    }

    render() {
        return (
            <li className="w3-bar">
                <a href={this.state.href}>
                    <img src={this.state.imgSrc} alt={this.state.text} download={this.state.download} className="w3-bar-item w3-hide-small" style={{width:'80px'}}/>
                    <div className="w3-bar-item">
                        <span className="w3-large">{this.state.text}</span><br/>
                        <span>{this.state.textSmall}</span>
                    </div>
                </a>
            </li>
        )
    }
}
export default QuickLink;