import React from 'react'
import pure_white from '../../../images/col/samples/arch/019_45_pure_white.jpg'
import white_alum from '../../../images/col/samples/arch/925_60_white_alum.jpg'
import grey_alum from '../../../images/col/samples/arch/911_61_grey_alum.jpg'
import tor_grey from '../../../images/col/samples/arch/649_64_tor_grey.jpg'
import graph_black from '../../../images/col/samples/arch/921_62_graph_black.jpg'

function Architecturni_Col() {

    return(

        <div className="w3-padding w3-mobile"  style={{display: "block"}}>

            <table class="w3-table w3-bordered w3-padding w3-mobile">
                <tr>
                    <th>Цвят</th>
                    <th>Име</th>
                </tr>
                <tr>
                    <td><img src={pure_white} className="w3-mobile"></img></td>
                    <td>DEC.019/45 Чисто бяло</td>
                </tr>
                <tr>
                    <td><img src={white_alum} className="w3-mobile"></img></td>
                    <td>DEC.925/60 Бял алуминий</td>
                </tr>
                <tr>
                    <td><img src={grey_alum} className="w3-mobile"></img></td>
                    <td>DEC.911/61 Сив алуминий</td>
                </tr>
                <tr>
                    <td><img src={tor_grey} className="w3-mobile"></img></td>
                    <td>DEC.649/64 Ториево сиво</td>
                </tr>
                <tr>
                    <td><img src={graph_black} className="w3-mobile"></img></td>
                    <td>DEC.921/62 Графитено черно</td>
                </tr>
            </table>

        </div>
    )
}

export default Architecturni_Col