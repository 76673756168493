import React from 'react'
import QuickLink from '../components/QuickLink'
import vid_icon from '../images/icons/vid_icon.png'
import Accordion from '../components/Accordion'
import GalleryTab from '../components/GalleryTab'

import GalleryProz from '../navbar_pages/gallery_content/gallery_proz'
import GalleryVrati from '../navbar_pages/gallery_content/gallery_vrati'


function Gallery() {

  const items = [
    {
      id: "proz",
      title: "Прозорци",
      content: GalleryProz
    },
    {
      id: "vrati",
      title: "Врати",
      content: GalleryVrati
    }
  ]

  const buttons = [
    {
      text: "Прозорци"
    },
    {
      text: "Врати"
    }
  ]

  return (
    <div className="w3-padding">
      <h1>Галерия</h1>

      <div className="w3-padding">
        <GalleryTab itemArr={items} btnArr={buttons}></GalleryTab>
      </div>

      {/* <Accordion sectionArr={accItems}></Accordion> */}

      <div className="w3-padding w3-margin-top w3-margin-bottom">
        <ul className="w3-ul w3-card-4 w3-hoverable">
            <QuickLink text="Галерия" textSmall="Видео" imgSrc={vid_icon} href="/video" />
        </ul>
      </div>
    </div>
  )
}
  
  export default Gallery;