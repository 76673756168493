import React from 'react'
import trad_tuscany from '../../../images/col/trad_tuscany.jpg'
import trad_oregon from '../../../images/col/trad_oregon.jpg'
import ModalBoxBtn from '../../../components/ModalBoxBtn'
import Traditional_Col from './traditional_col'

function Traditional() {
    return (
        <div>
            <div>
                <h5>Топлите дървесни тонове и фините несиметрични дървесни шарки придават естествен вид и допълват перфектно както 
                    традиционни, така и модерни архитектурни стилове.</h5>
                <p>
                    Традиционните цветове са в естествени тонове, с фини или едри дървесни шарки и съответно, с по-фина или по-груба 
                    структура на повърхността. Двустранните фолиа се поставят върху съответстващи по цвят кафяви профили, за да може и при 
                    отворен прозорец да се постигне хармонична визия.
                </p>
            </div>

            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile" style={{width: "60%"}}>
                    <div className="w3-padding" style={{display: 'inline-block'}}>
                        <img src={trad_tuscany}/>
                        <p>Традиционни цветове / Тоскана</p>
                    </div> 
                    <div className="w3-padding" style={{display: 'inline-block'}}>
                        <img src={trad_oregon}/> 
                        <p>Традиционни цветове / Орегон 4</p>
                    </div>
                </div>
                <div className="w3-cell w3-padding w3-mobile" style={{verticalAlign: "middle"}}>
                    <ModalBoxBtn text="Разгледайте палитрата" id="traditional" component={Traditional_Col}/>
                </div>
            </div>
        </div>
    )
}

export default Traditional