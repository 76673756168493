import React, { Component } from 'react'
import './Accordion.css'

class AccSection extends Component{
    constructor(props) {
        super(props)
        this.state = {
            text: props.text,
            content: props.content,
            id: props.id
        }
    }

    btnClick(evt){
        let btn = evt.target
        btn.classList.toggle("active")
        let panel = btn.nextElementSibling
        let sp = btn.children[0]
        if (panel.style.display === "block"){ 
            panel.style.display = "none"
            sp.textContent = "+"
        }
        else{ 
            panel.style.display = "block"
            sp.textContent = "-"
        }
    }

    render() {
        return (
            <div>
                <button onClick={this.btnClick} className="accordion" id={this.state.id}>{this.state.text}<span className="plusBtn">+</span></button>
                <div className="panel">
                    <div>{this.state.content()}</div>
                </div>
            </div>
        )
    }
}

class Accordion extends Component{
    constructor(props) {
        super(props)
        this.state = {
            sectionArr: props.sectionArr || []        
        }
    }

    render() { 

        const items = []
        for(let i=0; i<this.state.sectionArr.length; i++){
            let s = this.state.sectionArr[i]
            items.push(<AccSection text={s.text} content={s.content} />)
        }

        return (
            <div className="w3-padding">
                {items}
            </div>
        )
    }

}

export default Accordion