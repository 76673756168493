import React from 'react'
import QuickLink from '../../components/QuickLink'

import vrati_eforte_front from '../../images/vrati/vrati_eforte_front_resize_crop.jpg'
import vrati_eforte_cross_sec_crop from '../../images/vrati/vrati_eforte_cross_sec_crop.jpg'
import vrati_eforte_schematic from '../../images/vrati/vrati_eforte_schematic.jpg'
import vrati_eforte_orange from '../../images/vrati/vrati_eforte_orange_resize_crop.jpg'
import vrati_eforte_house from '../../images/vrati/vrati_eforte_house_resize_crop.jpg'
import vrati_eforte_fire from '../../images/vrati/vrati_eforte_fire_resize.jpg'

import col_icon from '../../images/icons/col_icon.png'

function Vrati_Eforte() {

    return(

        <div>

            <h1 className="w3-padding">ВРАТИ EFORTE</h1>
            <div className="w3-cell-row">
                <img src={vrati_eforte_front} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-padding w3-mobile">
                    <h2>Висококачествени врати със стабилна и солидна конструкция, перфектна топлоизолация и изискан дизайн</h2>
                    <p>Входни, вътрешни, портални, балконски, плъзгащи се или тип хармоника - PVC вратите на 
                    Декьонинк са стабилни и сигурни. Изберете подходящите цветове и форми за перфектния дизайн на Вашите помещения и на 
                    фасадата.</p>
                    <p>Eforte е 5-камерен профил за врати с отлични изолационни свойства. С перфектен коефициент на топлопреминаване до 
                    Uf = 1,3 W/m2K, вратите Eforte са подходящи за пасивни къщи и винаги, когато пестенето на енергия е приоритет. 
                    Опционално, входната врата може да бъде изработена с допълнителен съвременен панел, който я прави да изглежда като 
                    отлята. Панелът може да бъде изработен от алуминий, епоксидна смола или стъкло. По този начин профилът е покрит от 
                    външната страна, като се получава безупречно гладка повърхност, подчертаваща елегантността на входното пространство.</p>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={vrati_eforte_orange} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-padding w3-mobile">
                    <h2>Easystep – прагът с височина от нула сантиметра, през който се преминава безпрепятствено</h2>
                    <p>Дори праговете на входни врати с височина от едва 1-2 см крият известен риск от спъване, когато става въпрос за деца 
                    или възрастни хора. Разположеният на едно ниво с пода праг Easystep, който се използва при системата Eforte, вече не 
                    представлява препятствие за движението на хора, колички, колелa и др. Като термично разделен праг, той оптимизира контакта 
                    с пода, като предотвратява големи топлинни загуби.</p>
                    <p>Допълнителното заключване в областтта на прага на PVC вратата – входна, балконска, портална, плъзгаща се или хармоника, 
                    подобрява охраната, а самият праг улеснява почистващите дейности благодарение на премахващата се защитна поставка.</p>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={vrati_eforte_schematic} className="w3-padding w3-mobile"></img>
                <img src={vrati_eforte_cross_sec_crop} className="w3-padding w3-mobile"></img>
                
                <p className="w3-cell w3-padding w3-mobile">
                    <h2 className="w3-margin-left">Характеристики</h2>      
                    <ul>
                        <li>Дълбочина на крилото от 84 мм</li>
                        <li>5-камерна технология</li>
                        <li>Коефициент на топлопреминаване на рамката до Uf = 1,3 W/m2K</li>
                        <li>Побира панели с дебелина до 76 мм</li>
                        <li>Панелите могат да бъдат изработени от алуминий, епоксидна смола или стъкло</li>
                        <li>Стабилна конструкция на рамката на PVC вратата и изключителна устойчивост против изкривяване с помощта на стоманена 
                            армировка с дебелина от 2,5 мм, позволяваща да се изпълняват и елементи с по-големи размери</li>
                        <li>Уплътнителна система Eforte: необходимост от малка сила при затваряне и висока водонепроницаемост</li>
                        <li>Красив дизайн с елегантни застъпващи елементи</li>
                        <li>20-градусави скосявания</li>
                        <li>Метално подсилени ъгли като опция: отлети ъглови скоби от цинк за засилена устойчивост и стабилност</li>
                        <li>Възможност за обков с интегрирано многоточково заключване и специални приспособления за постигане на много високо 
                            ниво на сигурност в съответствие с DIN-EN 1627</li>
                    </ul>

                    <hr></hr>
 
                    <p className="w3-padding">
                        <h2>Сигурност и устойчивост в продължение на 20 години</h2>
                        <p>Входните PVC врати Eforte са с доказано качество, с приблизително 100 000 цикъла на отваряне и затваряне, 
                        съответстващи на около 20 години експлоатационен живот.</p>
                        <p>Солидната конструкция на рамките на входните PVC врати е подсилена със стоманена армировка с дебелина от 2,5 мм, 
                        както и със заздравени ъгли посредством отлети ъглови скоби от цинк. Включването на многоточково заключване и 
                        специалните приспособления, могат да повишат допълнително нивото на защита на входната врата.</p>
                    </p>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">   
                <div className="w3-cell w3-padding w3-mobile"> 
                    <img src={vrati_eforte_house} className=" w3-mobile"/> 
                </div>
                <div className="w3-cell w3-mobile"> 
                    <img src={vrati_eforte_fire} className=" w3-mobile"/> 
                </div>
            </div>

            <hr></hr>

            <div>
                <p className="w3-padding">
                    <h2>Богат избор на цветове за индивидуално оформление</h2>
                    Можете да избирате между 40 различни декора за вашите врати. Палитрата обхваща универсални цветове, декорации с вид на 
                    дърво, повърхности с перлена структура или с вид на алуминий. 
                    Цветовете са групирани по архитектурни стилове, за да отговорят на всеки индивидуален вкус.
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за цветовата програма на Декьонинк" imgSrc={col_icon} href="/col_pvc" />
                </ul>
            </div>

        </div>
    )
}

export default Vrati_Eforte