import  React  from 'react'
import Map from '../../components/Map'

function Contact() {

    return (
      <div className="w3-padding">
            <h1>Контакти</h1>

            <h2><b>Управител</b></h2> 
            <div>
            <div className="w3-card w3-cell w3-padding w3-mobile">
                  <p>МИХАИЛ ИВАНОВ</p>
                  <p>+359 88 859 5485</p>
                  <p><a href="mailto:sofia@trebelbulgaria.com">sofia@trebelbulgaria.com</a></p>
                  <p><a href="mailto:trebel@abv.bg">trebel@abv.bg</a></p> 
                  </div>
            </div>
            
            <hr />

            <h2><b>Складове</b></h2> 
            
            <div className="w3-cell-row">
                  <div className="w3-cell w3-card w3-padding w3-mobile">
                        <p><b>СКЛАД БЕРКОВИЦА</b></p>
                        <p>УЛ. „ХАДЖИ ПЕТЪР ИЛИЕВ“ № 2</p>
                        <p></p>
                        <p><a href="mailto:berkovica@trebelbulgaria.com">berkovica@trebelbulgaria.com</a></p>
                        <p><a href="mailto:trebel@abv.bg">trebel@abv.bg</a></p> 
                        <p></p>
                        <p>НАЧАЛНИК СКЛАД: АЛЕКСАНДЪР АЛЕКСАНДРОВ</p>
                        <p>+359 88 978 4196</p>
                  </div>
                  <div className="w3-cell w3-mobile">&nbsp;</div>
                  <div className="w3-cell  w3-mobile" style={{width:"50vw"}} id="map1">
                        <Map id={"top-map"} markerPosition={{ lat: 43.241454, lng: 23.138604 }}/>
                  </div>
            </div>
            <div className="w3-cell-row" >&nbsp;</div>
            <div className="w3-cell-row">
                  <div className="w3-card w3-cell w3-padding w3-mobile">
                        <p><b>СКЛАД ПЛОВДИВ</b></p>
                        <p>БУЛ. „КУКЛЕНСКО ШОСЕ“ № 9Ж, СКЛАД 5</p>
                        <p></p>
                        <p><a href="mailto:plovdiv@trebelbulgaria.com">plovdiv@trebelbulgaria.com</a></p>
                        <p><a href="mailto:trebel@abv.bg">trebel@abv.bg</a></p> 
                        <p></p>
                        <p>НАЧАЛНИК СКЛАД: АТАНАС ПАНАЙОТОВ</p>
                        <p>+359 89 891 6509</p>
                  </div>
                  <div className="w3-cell w3-mobile">&nbsp;</div>
                  <div className="w3-cell  w3-mobile" style={{width:"50vw"}} id="map2">
                        <Map id={"bottom-map"} markerPosition={{ lat: 42.124727, lng: 24.7601182 }}/>
                  </div>   
            </div>
            
      </div>
  
    )
}
  
  
  export default Contact