import React from 'react'
import arch_graphite_black from '../../../images/col/arch_graphite_black.jpg'
import arch_white_alum from '../../../images/col/arch_white_alum.jpg'
import ModalBoxBtn from '../../../components/ModalBoxBtn'
import Architecturni_Col from './architecturni_col'

function Architecturni() {
    return (
        <div>
            <div>
                <h5>Елегантният вид на алуминий и метал на първокласните Titanium Plus бои в изискани сиви нюанси подчертава модерния характер на всеки дом или бизнес сграда.</h5>
                <p>
                    Архитектурните цветове са подходящи за много взискателно архитектурно оформление на сградите. Изисканите сиви нюанси и 
                    пигментите с металически ефекти, придаващи вид на алуминий, акцентират върху модерния характер на фасадата. 
                    Висококачествената многослойна структура осигурява изключителна устойчивост на фолиата към неблагоприятни метеорологични 
                    условия, UV лъчи и мръсотия (лесно премахване на графити).
                </p>
            </div>

            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile" style={{width: "60%"}}>
                    <div className="w3-padding" style={{display: 'inline-block'}}>
                        <img src={arch_graphite_black}/>
                        <p>Архитектурни цветове / Графитено черно</p>
                    </div> 
                    <div className="w3-padding" style={{display: 'inline-block'}}>
                        <img src={arch_white_alum}/> 
                        <p>Архитектурни цветове / Бял алуминий</p>
                    </div>
                </div>
                <div className="w3-cell w3-padding w3-mobile" style={{verticalAlign: "middle"}}>
                    <ModalBoxBtn text="Разгледайте палитрата" id="architecturni" component={Architecturni_Col}/>
                </div>
            </div>
        </div>
    )
}

export default Architecturni