import React from 'react'

function Oblitz_Twinson_Montage() {

    return (

        <div>

            <div className="w3-row-padding">
                <h5><i>Лесен монтаж</i></h5>
                <p>Затворената система за фасадни облицовки има скрита клип система. Чрез конструкцията за  присъединяване тип глъб-зъб 
                    всеки отделен елемент на фасадата се монтира бързо и лесно – както хоризонтално, така и вертикално. Двустенните профили 
                    на Twinson осигуряват висока стабилност и разстояние до 50 см между летвите на поддържащата конструкция.</p>
            </div>

        </div>
    )
}

export default Oblitz_Twinson_Montage