import React from 'react';
import L from 'leaflet';

class Map extends React.Component {
  componentDidMount() {
    this.map = L.map(this.props.id, {
      center: [this.props.markerPosition.lat, this.props.markerPosition.lng],
      zoom: 16,
      layers: [
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }),
      ]
    })
    
    this.marker = L.marker(this.props.markerPosition).addTo(this.map)
  }

  render() {
      return <div id={this.props.id} className="w3-cell" style={{width:"55vw", height:"40vh"}}></div>
  }
}

export default Map;