import React from 'react'

function News() {
    const stuff = require('../../stuff.json') 
    console.log(stuff)
    return (
          <div className="w3-padding">
                <h1>Новини/промоции</h1>
                <h5>20/09/19</h5>
                <p>Требел 16 вече разполага с комплекти инструменти за новата серия "Елеганте" (Elegante).</p>
                <hr></hr>
                <h5>20/09/19</h5>
                <p>Вижте нашите нови видео материали за Easystep <a href="/video">тук</a></p>        
          </div>
    );
  }
  
  export default News;