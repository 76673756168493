import React from 'react'
import ModalBox from '../components/ModalBox'

import vr_arcade_cover from '../images/vrati/vr_arcade_cover_crop.jpg'
import vr_eforte_cover from '../images/vrati/vr_eforte_cover_crop.jpg'
import vr_elegante_cover from '../images/vrati/vr_elegante_cover_crop.jpg'

import Vrati_Arcade from './text/vrati_arcade.js'
import Vrati_Eforte from './text/vrati_eforte.js'
import Vrati_Elegante from './text/vrati_elegante'

function ComponentTextArcade() {
  return (
    <div>
      <h4>ARCADE</h4>
      <h6><i>Елегантна врата, предлагаща комфорт, сигурност и богати възможности за избор на дизайн, подходящ за всеки архитектурен стил.</i></h6>
    </div>
  )
}

function ComponentTextEforte() {
return (
  <div>
    <h4>EFORTE</h4>
    <h6><i>Висококачествени врати от PVC с дълбочина на профила от 84 мм със стабилна и солидна конструкция, перфектна топлоизолация и изискан дизайн.</i></h6>
  </div>
)
}

function ComponentTextElegante() {
return (
  <div>
    <h4>ELEGANTE</h4>
    <h6><i>Изключителна изолация с несравним елегантен дизайн, носител на престижната европейска награда Red Dot
      2019 в категорията „Продуктов дизайн“.</i></h6>
  </div>
)
}


function Vrati() {

    return (
      <div className="w3-padding">
        <h1>Врати</h1>
        <p>
          Входната врата и вратите за тераси и балкони трябва да предлагат солидна защита на дома и
          офиса. PVC профилите на Декьонинк са така изработени, че вратите – входни, вътрешни,
          портални, балконски, плъзгащи се, тип хармоника да са особено стабилни и здрави. Изберете
          подходящия дизайн между многото варианти за цветове и форми, който перфектно да пасне на
          фасадата на Вашата къща, апартамент, офис.
        </p>

        <ModalBox id="arcade" imgPath={vr_arcade_cover} textComp={ComponentTextArcade} text="Arcade" component={Vrati_Arcade}/>        
        <ModalBox id="eforte" imgPath={vr_eforte_cover} textComp={ComponentTextEforte} text="Eforte" component={Vrati_Eforte}/>        
        <ModalBox id="elegante" imgPath={vr_elegante_cover} textComp={ComponentTextElegante} text="Elegante" component={Vrati_Elegante}/>             
      </div>
    );
  }
  
  export default Vrati;