import React from 'react'
import GalleryImg from '../../components/GalleryImg'

import arcade_house from '../../images/gallery/proz/arcade.jpg'
import eforte_house from '../../images/gallery/proz/eforte.jpg'
import elegante_house from '../../images/gallery/proz/elegante1.jpg'

import elegante_out from '../../images/gallery/proz/elegante2.jpg'
import elegante_in from '../../images/gallery/proz/elegante3.jpg'
import elegante_closeup from '../../images/gallery/proz/elegante4.jpg'

function GalleryProz() {

    return (

        <div>

            <h3 className="w3-padding"><i><a href="/prozortsi">Към прозорци &#128279;</a></i></h3>
            
            <div className="w3-row-padding">
                <div className="w3-third">
                    <GalleryImg imgId="img1" modalId="modal1" image={arcade_house} text="Сграда с Arcade"></GalleryImg>
                </div>
                <div className="w3-third">
                    <GalleryImg imgId="img1" modalId="modal1" image={eforte_house} text="Сграда с Eforte"></GalleryImg>
                </div>
                <div className="w3-third">
                    <GalleryImg imgId="img1" modalId="modal1" image={elegante_house} text="Сграда с Elegante"></GalleryImg>
                </div>
            </div>

            <hr></hr>

            <div className="w3-row-padding">
                <div className="w3-third">
                    <GalleryImg imgId="img1" modalId="modal1" image={elegante_out} text="Elegante отвън"></GalleryImg>
                </div>
                <div className="w3-third">
                    <GalleryImg imgId="img1" modalId="modal1" image={elegante_in} text="Elegante отвътре"></GalleryImg>
                </div>
                <div className="w3-third">
                    <GalleryImg imgId="img1" modalId="modal1" image={elegante_closeup} text="Elegante"></GalleryImg>
                </div>
            </div>

            <hr></hr>

            <div id="modal1" style={{display: "none"}} className="w3-modal" onClick={() => { document.getElementById("modal1").style.display = 'none' }}>
                <span className="w3-button w3-hover-red w3-xlarge w3-display-topright">&times;</span>
                <div className="w3-modal-content w3-animate-zoom">
                    <img alt="cat" id="img1" style={{ width: "100%" }} />
                </div>
            </div>

        </div>
    )
}

export default GalleryProz