import React from 'react'
import tecnocor_zaobl2 from '../../images/pluz/tecnocor_zaobl2_resize.jpg'
import tecnocor_ex from '../../images/pluz/tecnocor_ex_resize.jpg'
import tecnocor_cross_sec from '../../images/pluz/tecnocor_cross_sec_resize.jpg'
import tecnocor_window from '../../images/pluz/tecnocor_window.jpg'
import tecnocor_cover from '../../images/pluz/tecnocor_cover_resize.jpg'
import QuickLink from '../../components/QuickLink'
import col_icon from '../../images/icons/col_icon.png'


function Pluz_Tecnocor() {

    return (
        <div>
            <h1 className="w3-padding">ПЛЪЗГАНЕ TECNOCOR>2</h1>
            <div className="w3-cell-row">
                <img src={tecnocor_window} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-padding w3-mobile">
                    <p>Системата Tecnocor> 2 съчетава предимствата на плъзгащата система със свойствата на PVC, за да постигне отлична 
                    функционалност, звуко- и топлоизолация.</p>
                    <p>Профилите са проектирани така, че да ограничат визуалното въздействие на рамката до минимум. Благодарение на специалните 
                    си усилвания и инерционни характеристики, профилите могат да се използват както за прозорци, така и за плъзгащи се врати 
                    с големи размери, като се запазват свойствата им.</p>
                    <p>Трябва да подчертаем и възможността за включване на трета допълнителна релса, която позволява отварянето на две трети от 
                    прозореца, вместо отваряне само наполовина, което осигуряват системите с две релси.</p>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={tecnocor_cover} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-padding w3-mobile">
                    <h2>Характеристики</h2>      
                    <ul>
                        <li>Каса и стъклодържател със заоблена изчистена форма</li>
                        <li>Остъкляване от 6 до 29 мм</li>
                        <li>Регулируеми колелца за по-добро движение на крилата върху рамката</li>
                        <li>Допълнително се поставя плъзгаща се алуминиева релса</li>
                        <li>Алуминиево средно заключване, което можете да използвате и като дръжка</li>
                        <li>Специално средно заключване за врати и плъзгане с големи размери</li>
                        <li>Трета допълнителна релса за осъществяване на плъзгането в 3 равнини</li>
                    </ul>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={tecnocor_ex} className="w3-cell w3-padding w3-mobile"></img>
                <img src={tecnocor_zaobl2} className="w3-cell w3-padding w3-mobile"></img>
                <img src={tecnocor_cross_sec} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-margin-top w3-padding w3-mobile">
                    <p>В името на хармоничен компромис между класиката и модерния стил, дизайнът на Tecnocor>2 е леко заоблен. Изчистеният 
                    елегантен вид на Tecnocor>2 превръща системата в идеална алтернатива на алуминиевите плъзгащи се врати и прозорци, но 
                    и с предимствата на PVC: дълготрайност, лесна поддръжка и отлична изолация.</p>
                    <p>Средното заключване – дръжка завършва цялостната философия на Tecnocor>2. Монтиран по цялата височина на прозореца, 
                    този профил увеличава здравината на целия елемент. По този начин позволява на големи тройни стъклопакети да бъдат 
                    монтирани без компромис в издръжливостта на вятър и вода. И за да бъде перфектно завършена визията му, фиксиращите 
                    винтове са напълно невидими.</p>
                    <hr></hr>
                    <p>Tecnocor>2 е създадена така, че да се вписва във всякакви фасади и различни интериорни решения.</p>
                    <p>Избирайки между различните варианти за законтряне на стъклото, вие може да смените напълно външния вид на плъзгането.</p>
                    <p>Профилът Tecnocor е идеално допълнение към всички прозоречни системи на Декьонинк. Използвайки ги заедно, вие създавате 
                    един хомогенен прозорец.</p>
                    <hr></hr>
                    <p>Пропорциите и геометрията на профилите са избрани така, че да могат да се използват висококачествени четки за по-добро 
                    уплътняване. Четките са предварително поставени върху профила и имат две основни функции: чрез тях се избягва триенето по 
                    време на плъзгане и се постига максимална защита от проникване на влага. Геометрията на профила гарантира перфектен дренаж, 
                    благодарение на по-доброто отводняване.</p>
                    <p>PVC е един от най-добрите материали за звуко- и топлоизолация. При системата Tecnocor трите камери позволяват да се 
                    постигне отличен коефициент на топлопреминаване Uw (от 1.7 до 2.7 W/m2K), в зависимост от остъкляването. Ако използваме 
                    5 мм стъкла в 28 мм стъклопакет ще постигнем максимално добър коефициент на топлопреминаване в системата Tecnocor>2.</p>
                    <p>Тази алтернатива на плъзгащите се врати от алуминий съчетава големите размери с много по-добра топлоизолация на достъпна цена.</p>
                </p>
            </div>

            <hr></hr>

            <div>
                <p className="w3-padding">
                    <h2>Богат избор на цветове за индивидуално оформление</h2>
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за цветовата програма на Декьонинк" imgSrc={col_icon} href="/col_pvc" />
                </ul>
            </div>

        </div>
    );
  }
  
  export default Pluz_Tecnocor;