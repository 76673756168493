import React from 'react'
import oregon from '../../../images/col/samples/trad/623_43_oregon.jpg'
import winchester from '../../../images/col/samples/trad/109_79_winchester.jpg'
import mountain_pine from '../../../images/col/samples/trad/640_41_mountain_pine.jpg'
import rustic_cherry from '../../../images/col/samples/trad/146_81_rustic_cherry.jpg'
import nevada from '../../../images/col/samples/trad/638_18_nevada.jpg'
import amaretto_cherry from '../../../images/col/samples/trad/656_57_amaretto_cherry.jpg'
import cherry_fir from '../../../images/col/samples/trad/402_24_cherry_fir.jpg'
import soft_cherry from '../../../images/col/samples/trad/672_82_soft_cherry.jpg'
import tuscany from '../../../images/col/samples/trad/605_19_tuscany.jpg'
import cherry_blossom from '../../../images/col/samples/trad/673_83_cherry_blossom.jpg'
import oak from '../../../images/col/samples/trad/115_33_oak.jpg'
import makore from '../../../images/col/samples/trad/144_25_makore.jpg'
import irish_oak from '../../../images/col/samples/trad/145_80_irish_oak.jpg'
import black_cherry from '../../../images/col/samples/trad/111_29_black_cherry.jpg'

function Vechni_Col() {

    return(

        <div className="w3-padding w3-mobile"  style={{display: "block"}}>

            <table class="w3-table w3-bordered w3-padding w3-mobile">
                <tr>
                    <th>Цвят</th>
                    <th>Име</th>
                </tr>
                <tr>
                    <td><img src={oregon} className="w3-mobile"></img></td>
                    <td>DEC. 623/43 Орегон 4</td>
                </tr>
                <tr>
                    <td><img src={winchester} className="w3-mobile"></img></td>
                    <td>DEC. 109/79 Уинчестър ХА</td>
                </tr>
                <tr>
                    <td><img src={mountain_pine} className="w3-mobile"></img></td>
                    <td>DEC. 640/41 Планински бор</td>
                </tr>
                <tr>
                    <td><img src={rustic_cherry} className="w3-mobile"></img></td>
                    <td>DEC. 146/81 Череша рустик *</td>
                </tr>
                <tr>
                    <td><img src={nevada} className="w3-mobile"></img></td>
                    <td>DEC. 638/18 Невада</td>
                </tr>
                <tr>
                    <td><img src={amaretto_cherry} className="w3-mobile"></img></td>
                    <td>DEC. 656/57 Амарето череша *</td>
                </tr>
                <tr>
                    <td><img src={cherry_fir} className="w3-mobile"></img></td>
                    <td>DEC. 402/24 Череша – дугласка ела</td>
                </tr>
                <tr>
                    <td><img src={soft_cherry} className="w3-mobile"></img></td>
                    <td>DEC. 672/82 Мека череша *</td>
                </tr>
                <tr>
                    <td><img src={tuscany} className="w3-mobile"></img></td>
                    <td>DEC. 605/19 Тоскана</td>
                </tr>
                <tr>
                    <td><img src={cherry_blossom} className="w3-mobile"></img></td>
                    <td>DEC. 673/83 Цвят на череша *</td>
                </tr>
                <tr>
                    <td><img src={oak} className="w3-mobile"></img></td>
                    <td>DEC. 115/33 Дъб ST-G</td>
                </tr>
                <tr>
                    <td><img src={makore} className="w3-mobile"></img></td>
                    <td>DEC. 144/25 Макоре</td>
                </tr>
                <tr>
                    <td><img src={irish_oak} className="w3-mobile"></img></td>
                    <td>DEC. 145/80 Ирландски дъб *</td>
                </tr>
                <tr>
                    <td><img src={black_cherry} className="w3-mobile"></img></td>
                    <td>DEC. 111/29 Черна череша</td>
                </tr>
            </table>

            <hr></hr>
            <h5>* с по-фина структура на повърхността</h5>

        </div>
    )
}

export default Vechni_Col