import React from 'react'
import ModalBoxBtn from '../../../components/ModalBoxBtn'
import ex from '../../../images/col/fasadi_tw_ex.jpg'
import col from '../../../images/col/samples/twinson-pvc/fasadi_tw.jpg'

function FasadiTw_Col() {

    return(

        <div className="w3-mobile">
            <img src={col} style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%"}}/>
        </div>
    )
}

function FasadiTw() {

    return(

        <div>
        
            <div className="w3-cell-row">
                <div className="w3-cell w3-padding w3-mobile" style={{width: "60%"}}>
                    <p>
                        Можете да вземете профилите за облицовка на фасадите от Twinson в три различни, естествени цвята. Няма нужда от 
                        боядисване, а цветовете запазват чудесната си визия за много дълго време. Лесната поддръжка позволява дълготрайна 
                        употреба.
                    </p>
                </div>
                <div className="w3-cell w3-padding w3-mobile" style={{verticalAlign: "middle"}}>
                    <ModalBoxBtn text="Разгледайте палитрата" id="fasadiTw" component={FasadiTw_Col}/>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <div className="w3-cell w3-padding" style={{width: "60%"}}>
                    <p><i>Забележка:</i> </p>
                    <p style={{fontSize: "13px"}}>
                        Фасадните облицовъчни профили, изработени от дървонапълнен полимерен композитен материал Twinson, променят цвета си 
                        след монтаж на открито или при съхранение на открито. Този процес се ускорява вследствие на дъжд и UV лъчи. 
                        Независимо от това, след този естествен процес на атмосферни влияния, цветът се стабилизира (както се вижда от 
                        горната част на изображението) и издръжливостта му е гарантирана. Ако след монтажа се откриват цветови различия, 
                        със сигурност след известен период от време под въздействието на UV лъчите те ще се уеднаквят отново.
                    </p>
                    <img src={ex} />
                </div>
                <div className="w3-cell w3-padding" />
            </div>

        </div>
    )
}

export default FasadiTw