import React from 'react'
import arcade_proz from '../../images/proz/arcade_proz.jpg'
import arcade_zaobl from '../../images/proz/arcade_zaobl_resize.jpg'
import arcade_classic from '../../images/proz/arcade_classic.jpg'
import QuickLink from '../../components/QuickLink'
import col_icon from '../../images/icons/col_icon.png'

function Proz_Arcade() {

    return (
        <div>
            <h1 className="w3-padding">ПРОФИЛ ЗА ПРОЗОРЦИ ARCADE</h1>
            <div className="w3-cell-row">
                <img src={arcade_proz} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-mobile">
                    <h2>Класическа 5 камерна система</h2>
                    
                    <p>Висококачествено решение за съвременното строителство, дори при ограничен бюджет.
                    С изчистена форма и висока функционалност, Arcade осигурява стабилна конструкция и отлична топлоизолация на PVC 
                    прозорците. Със строителна ширина на профила от 71 мм и коефициент на топлопреминаване на рамката от 1,2 W/m2K, 
                    Arcade предлага познатия висок технически стандарт на Декьонинк.</p>
                    <p>Системата Arcade позволява разнообразни дизайнерски решения чрез многообразие от цветове и два варианта на формата 
                    на профилите.</p>
                    <p>Подходяща и за PVC входни, повдигащи се и плъзгащи се <a href="/vrati">врати</a>.</p>


                    <hr></hr>

                    <h2>Технически спецификации</h2>      
                    <ul>
                        <li>73/53 мм височина на профила Arcade, 5-камерен</li>
                        <li>63/43 мм височина на Arcade-Еco и Arcade-EPC</li>
                        <li>Коефициент на топлопреминаване на целия прозорец Uw до 0,74 W/m2K</li>
                        <li>Профилните конструкции – достъпни в два различни дизайна</li>
                        <li>Стъклопакети с дебелина до 47 мм за много добра топло- и шумоизолация</li>
                        <li>Коефициент на топлопреминаване на рамката Uf = 1,2 W/m2K</li>
                        <li>Вътрешен двустъпков стъклодържач за по-добра защита срещу крадци</li>
                        <li>Две периферни заваряващи се ТРЕ уплътнения</li>
                        <li>Шумоизолация до 44 dB (клас на шумоизолация 4)</li>
                        <li>40 разновидности на цветове</li>
                    </ul>
                </p>
            </div>
            <hr></hr>

            <div className="w3-cell-row w3-padding">
                <h2>Производство с грижа за природата</h2>
                <p>Следвайки философията си за затворен производствен цикъл и грижа за приордата, Декьонинк предлага освен стандартния 
                вариант на системата ARCADE и още две нейни разновидности: ARCADE-ECO и ARCADE-EPC.</p>
                <p>Профилът Arcade-Еco се произвежда изцяло от рециклирана суровина.</p>
                <p>Профилът Arcade-EPC (Eco Power Core) има сърцевина/ядро от рециклиран PVC.</p>
                <p>Рециклираните PVC профили преминават през същите тестови изпитвания, както и тези от суровия композитен материал, за да 
                се запази високото ниво на качество на марката. Избирайки Arcade-Еco или Arcade-EPC, можете да сте сигурни, че правите нещо 
                добро за природата.</p>
                <p>По-малките размери на Arcade-Еco и Arcade-EPC (63/43 мм) в сравнение със стандартния профил (73/53 мм) ги прави практично и 
                икономично решение. С тях можете да постигнете желаната функционалност без отстъпление в качеството.</p>
            </div>

            <hr></hr>
            <div className="w3-cell-row">   
                <div className="w3-cell w3-padding w3-mobile">
                    <img src={arcade_zaobl} className="w3-mobile"/> 
                    <p>Заоблено Аркаде с полуизпъкнало крило</p>
                </div>
                <div className="w3-cell w3-padding w3-mobile"> 
                    <img src={arcade_classic} className="w3-mobile"/> 
                    <p>Класическо Аркаде</p>
                </div>
            </div>

            <hr></hr>

            <div>
                <p className="w3-padding">
                    <h2>Богат избор на цветове за индивидуално оформление</h2>
                    Можете да избирате между 40 различни декора. Палитрата обхваща универсални цветове, декорации с вид на дърво, повърхности с перлена структура или с вид на алуминий. 
                    Цветовете са групирани по архитектурни стилове, за да отговорят на всеки индивидуален вкус.
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за цветовата програма на Декьонинк" imgSrc={col_icon} href="/col_pvc" />
                </ul>
            </div>

        </div>
    )
  }
  
  export default Proz_Arcade