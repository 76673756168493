import React from 'react'
import QuickLink from '../../components/QuickLink'
import Accordion from '../../components/Accordion'
import col_icon from '../../images/icons/col_icon.png'

import pvc_roof from '../../images/oblitz/pvc_roof.jpg'
import pvc_side from '../../images/oblitz/pvc_side_resize.jpg'
import pvc_wall from '../../images/oblitz/pvc_wall.jpg'
import pvc_windows from '../../images/oblitz/pvc_windows_resize.jpg'

import Oblitz_PVC_Montage from './oblitz_pvc_montage'


function Oblitz_PVC() {

    const item = [
        {
          text: "Монтаж",
          content: Oblitz_PVC_Montage
        }
      ]

    return (

        <div>
            <div className="w3-cell-row">
                <img src={pvc_side} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <img src={pvc_windows} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                
                <div className="w3-cell w3-padding  w3-mobile">
                    <h2>Облицовки за фасади и покриви от PVC</h2>
                    <p>Красивите и функционални облицовки от PVC на Декьонинк са изключително здрави, дълготрайни и съвсем лесни за 
                        поддръжка благодарение на висококачественото им филмово покритие.</p>

                    <p>В съчетание със система за топлоизолация, 
                        фасадните облицовки от PVC ще ви спестят разходи за енергия.</p> 
                        
                    <p>С профилите в различни цветове и дължини е възможно 
                        индивидуално оформление както на фасадата, така и на челните дъски, капчуците и всички видими елементи на покрива 
                        получават красива визия и продължителна защита.</p> 
                        
                    <p>Палитрата от цветове е адаптирана спрямо <a href="/prozortsi">прозорците</a> и <a href="/vrati">вратите</a> на Декьонинк.</p>

                    <hr></hr>

                    <h2>Характеристики</h2>
                    <ul>
                        <li>Облицовка от PVC твърда пяна (рециклирано PVC), подобна на застъпващи се дъски, с допълнително екструдирано покритие от PVC с дебелина 0,5 мм</li>
                        <li>Фасадни профили с дължина от 6 м и ширина от 150 мм</li>
                        <li>Възможност за съчетаване със системи за топлоизолация</li>
                        <li>Бърз монтаж – както вертикален, така и хоризонтален</li>
                        <li>Закрепване с болтове</li>
                        <li>Скрито закрепване</li>
                        <li>Система за присъединяване тип зъб-глеб – успоредно подреждане на фасадните елементи от само себе си</li>
                        <li>Висококачествено филмово покритие в 29 декора</li>
                        <li>Възможност завършващите профили да са в същите цветове като фасадните елементи</li>
                        <li>Различни завършващи профили</li>
                        <li>Красив дизайн благодарение на широкия V-образен отвор и леко извитата форма на фасадните елементи</li>
                    </ul>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <Accordion sectionArr={item}></Accordion>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={pvc_roof} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <img src={pvc_wall} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                
                <div className="w3-cell w3-padding  w3-mobile">
                    <h2>Облицовка на фронтон и покрив с ъглови и плоски профили от PVC</h2>
                    <p>Големият асортимент от облицовъчни системи, изработени от PVC, с принадлежности за облицовка на фронтони, ъгли, 
                        шевове, ръбове, странични части на касите на прозорци и места за преминаване, пасва на всички видове строителство. 
                        Повърхността е не само красива, но и предпазва дългосрочно от влиянието на атмосферните условия, а работата с тях 
                        е също толкова лесна, колкото и с дървесината.</p>

                    <hr></hr>

                    <h2>Характеристики</h2>
                    <ul>
                        <li>Комбинация от PVC твърда пяна и слой от твърд PVC</li>
                        <li>Налични в различни размери и форми</li>
                        <li>Ширини: 90 – 400 мм, дължина 6 м</li>
                        <li>Видимо закрепване посредством болтове от неръждаема стомана с цветна глава или пирони с пластмасова глава</li>
                        <li>Профили: скрито закрепване</li>
                        <li>Цялостна система от завършващи профили, принадлежности, вкл. с възможност за вентилация</li>
                        <li>Могат да се съчетават с профили с камери от PVC</li>
                        <li>Системата е съвместима с нискоенергийни къщи и къщи от дърво</li>
                        <li>Налични са оцветени или с горен слой от фолио (устойчиво на UV лъчи и отблъскващо мръсотията</li>
                    </ul>
                </div>
            </div>

            <hr></hr>

            <div className="w3-row-padding">
                <h2>29 различни цвята</h2>
                <p>
                    Можете да избирате между 29 различни цвята за PVC фасадата, формираща характера на вашата сграда. Красивата и 
                    функционална облицовка ще я направи устойчива за много години. Палитрата включва естествени дървесни несиметрични 
                    шарки, цветни или металически декори. За хармонична крайна визия, много от цветовете на фасадните профили са идентични 
                    с рамките на прозорците и вратите на Декьонинк. Вижте цветовете на PVC облицовките за фасади на Декьонинк и дайте 
                    уникален облик на вашата сграда.
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за палитрата на Twinson" imgSrc={col_icon} href="/col_oblitz" />
                </ul>
            </div>

        </div>
    )
}

export default Oblitz_PVC