// list of image cards on the main page

import React, { Component } from 'react'
import ImgCard from './ImgCard'

import pic1 from '../images/cat1.jpg'
import proz_main_pg from '../images/main_pg/proz_main_pg_sq.jpg'
import vrati_main_pg from '../images/main_pg/vrati_main_pg_alt.jpg'
import pluz_main_pg from '../images/main_pg/pluz_main_pg_sq.jpg'
import povd_main_pg from '../images/main_pg/povd_main_pg_sq.jpg'
import oblitz_main_pg from '../images/main_pg/oblitz_main_pg_sq.jpg'
import nastilki_main_pg from '../images/main_pg/nastilki_main_pg_sq.jpg'

class CardList extends Component{
    constructor(props) {
        super(props);
        this.state = {
          linkPathList: [
              '/prozortsi',
              '/vrati',
              '/pluzgane',
              '/pluzgane-povdigane',
              '/oblitsovki',
              '/nastilki-ogradi'
          ]

        };
    }

    renderImgCard(imgPath, text, href) {
        return (
            <ImgCard imgPath={imgPath} text={text} href={href} />
        )
    }

    render() {
        return (
            <div>
                <div className="w3-row-padding w3-margin-top">
                    {this.renderImgCard(proz_main_pg, "ПРОЗОРЦИ", this.state.linkPathList[0])}
                    {this.renderImgCard(vrati_main_pg, "ВРАТИ", this.state.linkPathList[1])}
                    {this.renderImgCard(pluz_main_pg, "ПЛЪЗГАНЕ", this.state.linkPathList[2])}
                </div>
                <div className="w3-row-padding w3-margin-top">
                    {this.renderImgCard(povd_main_pg, "ПЛЪЗГАНЕ С ПОВДИГАНЕ", this.state.linkPathList[3])}
                    {this.renderImgCard(oblitz_main_pg, "ОБЛИЦОВКИ ЗА ФАСАДИ И ПОКРИВИ", this.state.linkPathList[4])}
                    {this.renderImgCard(nastilki_main_pg, "НАСТИЛКИ ЗА ТЕРАСИ И ПЛЪТНИ ОГРАДИ", this.state.linkPathList[5])}
                </div>
            </div>
        )
    }
}

export default CardList;