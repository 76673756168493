import React from 'react'
import vechni_cream_white from '../../../images/col/vechni_cream_white.jpg'
import vechni_wine_red from '../../../images/col/vechni_wine_red.jpg'
import ModalBoxBtn from '../../../components/ModalBoxBtn'
import Vechni_Col from './vechni_col'

function Vechni() {
    return (
        <div>
            <div>
                <h5>Непреходните красиви цветове внасят цветни акценти върху фасадата на сградата. </h5>
                <p>
                    Непреходните цветове с несиметрични дървесни шарки, висока устойчивост и дълъг живот внасят прекрасни цветни акценти 
                    върху фасадата.  Кремаво бяло или мъхесто зелено подхождат идеално на традиционните архитектурни стилове, а цветни 
                    нюанси като червено или стоманено синьо се отркиват много често при индустриални сгради.
                </p>
            </div>

            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile" style={{width: "60%"}}>
                    <div className="w3-padding" style={{display: 'inline-block'}}>
                        <img src={vechni_cream_white}/>
                        <p>Вечни цветове / Кремаво бяло</p>
                    </div> 
                    <div className="w3-padding" style={{display: 'inline-block'}}>
                        <img src={vechni_wine_red}/> 
                        <p>Вечни цветове / Винено червено</p>
                    </div>
                </div>
                <div className="w3-cell w3-padding w3-mobile" style={{verticalAlign: "middle"}}>
                    <ModalBoxBtn text="Разгледайте палитрата" id="vechni" component={Vechni_Col}/>
                </div>
            </div>
        </div>
    )
}

export default Vechni