import React from 'react'
import grey from '../../../images/col/samples/classic/004_99_grey.jpg'
import gold_oak from '../../../images/col/samples/classic/110_48_gold_oak.jpg'
import anthracite from '../../../images/col/samples/classic/072_21_anthracite.jpg'
import walnut from '../../../images/col/samples/classic/154_21_walnut.jpg'
import anthracite_sm from '../../../images/col/samples/classic/666_02_anthracite_sm.jpg'
import mahogany from '../../../images/col/samples/classic/026_22_mahogany.jpg'
import green from '../../../images/col/samples/classic/006_66_green.jpg'
import dark_oak from '../../../images/col/samples/classic/025_34_dark_oak.jpg'
import brown from '../../../images/col/samples/classic/012_11_brown.jpg'

function Classic_Col() {

    return(

        <div className="w3-padding w3-mobile"  style={{display: "block"}}>

            <table class="w3-table w3-bordered w3-padding w3-mobile">
                <tr>
                    <th>Цвят</th>
                    <th>Име</th>
                </tr>
                <tr>
                    <td><img src={grey} className="w3-mobile"></img></td>
                    <td>DEC.004/99 Сиво</td>
                </tr>
                <tr>
                    <td><img src={gold_oak} className="w3-mobile"></img></td>
                    <td>DEC.110/48 Златен дъб</td>
                </tr>
                <tr>
                    <td><img src={anthracite} className="w3-mobile"></img></td>
                    <td>DEC.072/21 Антрацит</td>
                </tr>
                <tr>
                    <td><img src={walnut} className="w3-mobile"></img></td>
                    <td>DEC.154/21 Орех</td>
                </tr>
                <tr>
                    <td><img src={anthracite_sm} className="w3-mobile"></img></td>
                    <td>DEC.666/02 Антрацит гладко</td>
                </tr>
                <tr>
                    <td><img src={mahogany} className="w3-mobile"></img></td>
                    <td>DEC.026/22 Махагон</td>
                </tr>
                <tr>
                    <td><img src={green} className="w3-mobile"></img></td>
                    <td>DEC.006/66 Зелено</td>                
                    </tr>
                <tr>
                    <td><img src={dark_oak} className="w3-mobile"></img></td>
                    <td>DEC.025/34 Тъмен дъб</td>                
                    </tr>
                <tr>
                    <td><img src={brown} className="w3-mobile"></img></td>
                    <td>DEC.012/11 Кафяво</td>                
                </tr>
            </table>

        </div>
    )
}

export default Classic_Col