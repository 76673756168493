import React from 'react'

function Oblitz_Alum_Montage() {

    return (

        <div>

            <div className="w3-row-padding">
                <p>Бърз и лесен монтаж чрез специалните закрепващи клипсове. Фасадните профили могат да се монтират както хоризонтално, 
                    така и вертикално. Двустенните профили се грижат за високата стабилност и позволяват отстояние между летвите на 
                    поддържащата конструкция до 50 см. За перфектни финални щрихи към алуминиевите фасадни облицовки се предлагат 
                    завършващи профили от елоксиран алуминий в черно, сребърно и кафяво.</p>
            </div>

        </div>
    )
}

export default Oblitz_Alum_Montage