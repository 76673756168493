import React, { Component } from 'react'

class GalleryImg extends Component {

    constructor(props){
        super(props)
        this.state = {
            image: props.image,
            text: props.text,
            imgId: props.imgId,
            modalId: props.modalId
        }
    }

    imgClick(event) {
        let element = event.target
        document.getElementById(this.state.imgId).src = element.src
        document.getElementById(this.state.modalId).style.margin = "auto"
    }

    render() {

        return (
            <div>

                <div className="w3-card">
                    <img alt="cat" src={this.state.image} style={{ width: "100%", cursor: "pointer" }} onClick={(e) => this.imgClick(e)} className="w3-hover-grayscale w3-mobile" />
                    <div className="w3-container">
                        <p>{this.state.text}</p>
                    </div>
                </div>

            </div>
        )
    }
}

export default GalleryImg