import React from 'react'
import QuickLink from '../../components/QuickLink'
import ogradi_outside from '../../images/nastilki/ogradi_outside.jpg'
import ogradi_inside from '../../images/nastilki/ogradi_inside.jpg'
import ogradi_clipping from '../../images/nastilki/ogradi_clipping.jpg'
import col_icon from '../../images/icons/col_icon.png'

function Nast_Ogradi() {
    
    return (

        <div>
            <div className="w3-cell-row">
                <img src={ogradi_outside} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">
                    <p>Системите за плътни огради изработени от Twinson предлагат визуална, приветлива, цялостна защита на външните ви 
                    пространства и се вписват с подходящ дизайн във всяка среда.</p>
                    <p>Системата за външни огради е перфектно допълнение към терасите от патентования дървонапълнен полимерен композитен 
                    материал Twinson.</p>
                    <p>Елементите на плътните огради се предлагат в две различни височини. По-ниската система с крайна височина от 1,15 м 
                    лесно се закрепя към бетона с помощта на болтове. По-високата система трябва да бъде бетонирана. След това, като 
                    крайна височина, тя става 1,84 м.</p>
                    <p>Стълбовете са изработени от висококачествен алуминий и се предлагат както в сребристо, така и в черно. Конструкцията 
                    позволява ъгловите връзки да са от нула до 180 градуса. Радиусът може да се регулира безстепенно. Системата е 
                    изключително здрава и може безпроблемно да се свърже с външните стени.</p>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={ogradi_inside} className="w3-cell w3-padding w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">
                    <h2>Елементи за плътни огради, подходящи за градини и тераси</h2>
                    <p>Като елементи на плътната ограда между стълбовете могат да се използват профили от системата Twinson Terrace. 
                    Плътната ограда се вписва перфектно към всяка външна площ на къщата, апартамента или офиса, като осигурява уют и 
                    уединение. Благодарение на материала Twinson системата е изключително издръжлива и лесна за поддържане. Twinson прави 
                    боядисването ненужно и запазва красивия си естествен дизайн в продължение на много години, независимо от влиянието на 
                    времето.</p>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={ogradi_clipping} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">
                    <h2>Характеристики</h2>
                    <ul>
                        <li>Профили от терасната система Terrace се използват като елементи на плътната ограда</li>
                        <li>Две видими височини: 1,15 м (за закрепване с болтове) или 1,84 м (стълбовете трябва да се бетонират)</li>
                        <li>Алуминиеви стълбове: елоксирани в сребристо или черно</li>
                        <li>Възможно е лесно свързване към външни стени</li>
                        <li>Безстепенно регулируем радиус на ъгловите връзки от нула до 180 градуса за различни строителни ситуации</li>
                        <li>Устойчива на насекоми</li>
                        <li>Водоотблъскваща</li>
                        <li>Изключително издръжлива</li>
                        <li>100% рециклируема</li>
                        <li>Без нужда от боядисване</li>
                    </ul>
                </div>
            </div>
            
            <div className="w3-row-padding">
                <p>Като алтернатива на вградените поставки предлагаме монтажни крака:</p>
                <ul>
                    <li>монтаж на крака отдясно/ отляво, външна част за Р9272 за системата за плътна ограда Р 9283</li>
                    <li>монтаж на крака за Р 9273 за системата за плътна ограда, средна част Р 9275</li>
                </ul>
                <p>Поставките трябва да се скъсят по съответен начин.</p>
            </div>

            <hr></hr>

            <div className="w3-row-padding">
                <h2>Цветовете на Twinson</h2>
                <p>
                    Елементите на плътната ограда на системата Twinson Terrace, наподобяващи естествено дърво, се предлагат в шест различни 
                    цвята с два вида повърхности – грапава и фино рифелувана. По този начин хармоничното цветово съчетаване с градината и 
                    терасата е гарантирано. Запознайте се с цветовете на Twinson и проектирайте своята перфектна ограда.
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за палитрата на Twinson" imgSrc={col_icon} href="/col_oblitz" />
                </ul>
            </div>
        </div>
    )
}

export default Nast_Ogradi