import React from 'react'
import ModalBox from '../components/ModalBox'
import arcade_proz_crop from '../images/proz/arcade_proz_crop2.jpg'
import eforte_alum_crop from '../images/proz/eforte_alum_crop2.jpg'
import elegante_cover_crop from '../images/proz/elegante_cover_crop.jpg'
import Proz_Arcade from './text/proz_arcade';
import Proz_Eforte from './text/proz_eforte';
import Proz_Elegante from './text/proz_elegante';

function ComponentTextArcade() {
    return (
      <div>
        <h4>ARCADE</h4>
        <h6><i>5 камерна система за PVC дограми, с дълбочина на профила от 71 мм</i></h6>
        <p style={{fontSize: "10pt"}}>
          PVC прозоречна система с отлично съотношение цена-качество, която осигурява съвременен стандарт на живот във вашата сграда.
        </p>
      </div>
    )
}

function ComponentTextEforte() {
  return (
    <div>
      <h4>EFORTE</h4>
      <h6><i>Висококачествена система за дограми с дълбочина на профила от 84 мм</i></h6>
      <p style={{fontSize: "10pt"}}>
        Модерна PVC прозоречна система от високия клас, без компромис в качеството, с отлични изолационни стойности.
      </p>
    </div>
  )
}

function ComponentTextElegante() {
  return (
    <div>
      <h4>ELEGANTE</h4>
      <h6><i>Модерeн, елегантен дизайн с алуминиев външен вид, съчетан с изключителни технически характеристики</i></h6>
      <p style={{fontSize: "10pt"}}> 
        Elegante от Deceuninck спечели престижната европейска награда Red Dot
        2019 в категорията „Продуктов дизайн“.
      </p>
    </div>
  )
}

function Proz() {

    return (
      <div className="w3-padding">
        <h1>Прозорци</h1>

        <p>
          PVC профилите за прозорци и врати на Декьонинк са разработени в различни иновативни
          системи и дизайни във всички класове PVC дограма, като предлагат отлични решения както за
          нови сгради, така и за обновяване и модернизиране. Прецизно изработени, в широка гама от
          цветове и лесни за монтаж, те впечатляват с изключителната си енергийна ефективност,
          издръжливост и дълъг живот.
        </p>

        <ModalBox id="arcade" imgPath={arcade_proz_crop} textComp={ComponentTextArcade} text="Arcade" component={Proz_Arcade}/>        
        <ModalBox id="eforte" imgPath={eforte_alum_crop} textComp={ComponentTextEforte} text="Eforte" component={Proz_Eforte}/>        
        <ModalBox id="elegante" imgPath={elegante_cover_crop} textComp={ComponentTextElegante} text="Elegante" component={Proz_Elegante}/>        
      </div>
    );
  }
  
  export default Proz;