import React from 'react'
import ModalBox from '../components/ModalBox'
import Oblitz_Twinson from './text/oblitz_twinson'
import Oblitz_PVC from './text/oblitz_pvc'
import Oblitz_Alum from './text/oblitz_alum'

import twinson_house from '../images/oblitz/twinson_house.jpg'
import pvc_windows from '../images/oblitz/pvc_windows_resize.jpg'
import alum_house from '../images/oblitz/alum_house_resize.jpg'

function ComponentTextTwinson() {
  return (
    <div>
      <h4>Облицовки за фасади и покриви от TWINSON</h4>
      <h6><i>Естествена визия на облицовката, сякаш изработена от дърво, в три красиви цвята. Забележителна устойчивост, удобна поддръжка, лесен монтаж.</i></h6>
    </div>
  )
}

function ComponentTextPVC() {
  return (
    <div>
      <h4>Облицовки за фасади и покриви от PVC</h4>
      <h6><i>Елегантна, функционална и лесна за поддръжка облицовка. Палитрата от цветове е адаптирана спрямо прозорците и вратите на Декьонинк.</i></h6>
    </div>
  )
}

function ComponentTextAluminium() {
  return (
    <div>
      <h4>Облицовки за фасади от алуминий</h4>
      <h6><i>Висококачествени облицовки, налични във всички цветове по RAL системата и всички специални цветове. Подходящи както за нови, така и за реновирани сгради.</i></h6>
    </div>
  )
}

function Oblitz() {

    return (
      <div className="w3-padding">
        <h1>Облицовки за фасади и покриви</h1>

        <p>
          Облицовките за фасади и покриви предпазват сградата от неблагоприятни метеорологични условия и прибавят акценти в дизайна, 
          подчертаващи индивидуалния архитектурен стил. Налични са профилни системи, изработени от патентования дървонапълнен полимерен 
          композитен материал Twinson, от висококачествено PVC и облицовки от алуминий.
        </p> 

        <ModalBox id="twinson_obl" imgPath={twinson_house} textComp={ComponentTextTwinson} text="Twinson" component={Oblitz_Twinson}/>        
        <ModalBox id="pvc_obl" imgPath={pvc_windows} textComp={ComponentTextPVC} text="PVC" component={Oblitz_PVC}/>        
        <ModalBox id="alum_obl" imgPath={alum_house} textComp={ComponentTextAluminium} text="Aluminium" component={Oblitz_Alum}/>        
      </div>
    );
  }
  
  export default Oblitz;