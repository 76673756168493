import React from 'react'
import QuickLink from '../../components/QuickLink'
import pdf_icon from '../../images/icons/pdf_icon.png'

function RestrictedCats() {

    return(

        <div>
            <div className="w3-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink href="/files/Техн. каталог HSAB476.pdf" download={true} text="HSAB476" textSmall="Техн. каталог" imgSrc={pdf_icon}></QuickLink>
                </ul>
            </div>

            <div className="w3-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink href="/files/Техн. каталог PROtex.pdf" download={true} text="PROtex" textSmall="Техн. каталог" imgSrc={pdf_icon}></QuickLink>
                </ul>
            </div>

            <div className="w3-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink href="/files/Техн. каталог Tecnocor 2.pdf" download={true} text="Tecnocor 2" textSmall="Техн. каталог" imgSrc={pdf_icon}></QuickLink>
                </ul>
            </div>

            <div className="w3-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink href="/files/Техн. каталог Окачени фасади.pdf" download={true} text="Окачени фасади" textSmall="Техн. каталог" imgSrc={pdf_icon}></QuickLink>
                </ul>
            </div>

            <div className="w3-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink href="/files/Техн. каталог Хармоника Arcade.pdf" download={true} text="Хармоника Arcade" textSmall="Техн. каталог" imgSrc={pdf_icon}></QuickLink>
                </ul>
            </div>

            <div className="w3-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink href="/files/Техн. каталог Хармоника Eforte.pdf" download={true} text="Хармоника Eforte" textSmall="Техн. каталог" imgSrc={pdf_icon}></QuickLink>
                </ul>
            </div>

            <div className="w3-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink href="/files/Техн. ръководство Arcade.pdf" download={true} text="Аrcade" textSmall="Техн. ръководство" imgSrc={pdf_icon}></QuickLink>
                </ul>
            </div>

            <div className="w3-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink href="/files/Техн. ръководство Eforte.pdf" download={true} text="Eforte" textSmall="Техн. ръководство" imgSrc={pdf_icon}></QuickLink>
                </ul>
            </div>

            <div className="w3-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink href="/files/Техн. ръководство Elegant.pdf" download={true} text="Elegant" textSmall="Техн. ръководство" imgSrc={pdf_icon}></QuickLink>
                </ul>
            </div>
        </div>
    )
}

export default RestrictedCats