// Modal Box

import React, { Component } from 'react'
// import ImgCard from './ImgCard'
import './ModalBox.css'

class ModalBoxBtn extends Component{
    constructor(props) {
        super(props)
        this.state = {
            imgPath: props.imgPath,
            text: props.text,
            textComp: props.textComp || function() { return props.text },
            href: props.href,
            id: props.id,
            idBtn: props.id + "_btn",
            idSpan: props.id + "_span",
            component: props.component || function() { return props.text }
        }
    }

   
    componentDidMount(){
        var modal = document.getElementById(this.state.id);

        // Get the button that opens the modal
        var btn = document.getElementById(this.state.idBtn);
        
        // Get the <span> element that closes the modal
        var span = document.getElementById(this.state.idSpan);
        
        // When the user clicks on the button, open the modal 
        btn.onclick = function() {  
            modal.style.display = "block";
        }
        
        // When the user clicks on <span> (x), close the modal
        span.onclick = function() {
            modal.style.display = "none";
        }
        
        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function(event) {
            if (event.target == modal) {
                modal.style.display = "none";
            }
        }
    }

    render() {
        return (
            <div>
                <div>
                    <button id={this.state.idBtn} style={{fontSize:"3vw"}} className="w3-button w3-blue-gray w3-padding-large w3-margin-bottom w3-cell">{this.state.text}</button>
                </div>

                <div id={this.state.id} className="modal">
                    <div className="modal-content">
                        <span id={this.state.idSpan} className="close">&times;</span>
                        {this.state.component()}
                    </div>
                </div>
            </div>
        )
    }
}
export default ModalBoxBtn;