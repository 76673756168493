import React from 'react'
import Accordion from '../../components/Accordion'
import QuickLink from '../../components/QuickLink'
import terasi_balcony from '../../images/nastilki/terasi_balcony.jpg'
import terasi_chairs from '../../images/nastilki/terasi_chairs.jpg'
import terasi_grass from '../../images/nastilki/terasi_grass.jpg'
import terasi_pool from '../../images/nastilki/terasi_pool.jpg'
import terasi_couch from '../../images/nastilki/terasi_couch_resize.jpg'
import terasi_umbrella from '../../images/nastilki/terasi_umbrella.jpg'
import terasi_kinds from '../../images/nastilki/terasi_kinds_resize.jpg'
import Nast_Terasi_Montage from './nast_terasi_montage.js'
import col_icon from '../../images/icons/col_icon.png'

function Nast_Terasi() {

    const item = [
        {
          text: "Монтаж",
          content: Nast_Terasi_Montage
        }
      ]

    return (

        <div>
            <h1 className="w3-padding">НАСТИЛКИ ЗА ТЕРАСИ ОТ TWINSON</h1>

            <div className="w3-cell-row">
                <img src={terasi_balcony} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">
                    <p>TWINSON е иновативен дървонапълнен композитен материал, който обединява най-доброто от дървото и PVC.</p>
                    <p>Twinson съчетава натуралността на дървото с лесната употреба, трайността и лесната поддръжка на PVC материала. 
                    Материалът е водоустойчив, здрав, гъвкав и с добър външен вид, и това са само някои от преимуществата му.</p>
                    <p>Изработваните от него профили се монтират най-често като фасадни облицовки или подова настилка в двора, градината, 
                    терасата, около басейна или в обществени заведения. Това го прави елегантно и надеждно решение за стилно оформяне на 
                    външното пространство, при това с гарантирана дълготрайност.</p>
                    <p>Twinson може да се рециклира 100%, не замърсява околната среда и е отлична алтернатива на твърдите видове дърво.</p>
                    <p>Профилите от Twinson се предлагат в красиви, естествени цветове - от бадемово-бежово до избеляло черно. Няма нужда 
                    от боядисване, а цветовете запазват чудесната си визия за много дълго време. Лесната поддръжка позволява дълготрайна 
                    употреба, а гаранцията е до 25 години.</p>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={terasi_grass} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">
                    <p>Профилите Twinson могат да се използват и от двете страни. Едната повърхност е грапава, а другата – фино рифелувана, 
                    като и двете страни са безопасни спрямо подхлъзване.</p>
                    <p>Системата има две разновидности: Twinson Terrace – за веранди, открити пространства около басейни, огради и вътрешен 
                    двор и Twinson Face –  предназначена за външни облицовки – от предната фасада до задната стена и от покрива до прозорците.</p>
                </div>
            </div>
           
            <hr></hr>

            <div className="w3-cell-row">
                <img src={terasi_couch} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <img src={terasi_pool} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <img src={terasi_chairs} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>

                <div className="w3-cell w3-padding w3-mobile">
                    <h2>Twinson Terrace – удобен и здрав под в двора</h2>
                    <p>С дъските Twinson Terrace се облицоват градински пътеки, вътрешни дворове и тераси, земята около басейни. 
                    Подовото покритие се поддържа лесно, устойчиво е на влага и натиск, не гние. Към това може да добавим удоволствието от 
                    гледката и комфорта при допир с него. </p>

                    <hr></hr>

                    <h2>Характеристики</h2>      
                    <ul>
                        <li>Профили с камери и естествена дървесна визия</li>
                        <li>140 мм (полезна ширина от 145 мм) х 28 мм</li>
                        <li>Две различни повърхности, които могат да се използват и от двете страни</li>
                        <li>Бърз монтаж чрез иновативна скрита клип система</li>
                        <li>Дължина на профилите от 4 / 4.5 / 5 / 6 метра</li>
                        <li>Безстепенно регулируеми крачета за изравняване на разлики във височините (от 35 до 215 мм)</li>
                        <li>Сертифицирана по PEFC</li>
                        <li>Печат за качества VHI на Qualitatsgemeinschaft Holzwerkstoffe e.V. 
                        (комисия за контрол на качеството на продуктите, изработени от дърво)</li>
                    </ul>

                    <h2>Дизайн</h2>
                    <ul>
                        <li>Завършване със завършващ профил, съответно перваз от Twinson или алуминиев профил</li>
                        <li>Хармонична визия – разстояние между фугите от едва 5 мм</li>
                        <li>Наличен е вентилационен профил за по-добра вентилация на пода на терасата</li>
                    </ul>

                    <h2>Качество</h2>
                    <ul>
                        <li>Клас на безопасност от подхлъзване R12</li>
                        <li>Клас на пожарна устойчивост В1</li>
                        <li>Товароносимост: точков капацитет на товароносимост – 100 кг; капацитет на товароносимост на повърхността – 1400 кг/м2</li>
                        <li>Устойчива на насекоми</li>
                        <li>Водоотблъскваща</li>
                        <li>100% рециклируема</li>
                        <li>Няма нужда от боядисване</li>
                        <li>Изключително здрава – до 25 години гаранция</li>
                    </ul>

                    <h2>Поддържаща конструкция</h2>
                    <ul>
                        <li>Разстояние на закрепване на поддържащата конструкция: 50 см</li>
                        <li>Три алуминиеви профила за поддържащата конструкцция: възможна е самоносеща конструкция за плаващ монтаж</li>
                        <li>Наличен е забележително нисък алуминиев носещ профил с височина от 16 мм – идеален за саниране на балкони и тераси</li>
                        <li>Възможна е самоносеща конструкция с алуминиев носещ профил 50х50 в комбинация със свързващ елемент Р 9468</li>
                    </ul>
                </div>
            </div>

            <hr></hr>
            
            <div className="w3-cell-row">
                <Accordion sectionArr={item}></Accordion>
            </div>

            <hr></hr>

            <h2 className="w3-padding">WPC настилките за тераси от Twinson се предлагат в три разновидности</h2>
            <div className="w3-cell-row">
                <img src={terasi_umbrella} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <img src={terasi_kinds} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">

                    <h5><i>Twinson Terrace</i></h5>
                    <div>
                        <p>Класичиският избор за всички пространства в градината и къщата. Тази WPC настилка предлага много добро съотношение 
                        цена-ползи и е подходяща за градински пътеки, вътрешни дворове, тераси и балкони. Имате избор от две повърхности: 
                        грапава и фино рифелувана.</p>
                        <p>За поддържаща конструкция може да се използват или летви от дърво, или стандартни WPC носещи профили от Twinson. 
                        Налични са и три профила за поддържаща конструкция от алуминий, подходящи за плаващ монтаж, за завинтване върху бетон 
                        или за монтаж без фиксиране към основата.</p>
                    </div>
                    <hr></hr>

                    <h5><i>Twinson Terrace Massive</i></h5>
                    <div>
                        <p>Компактна настилка, идеална за извити форми. Тази здрава настилка няма камери и може да се реже като дърво. Налична 
                        е с две различни повърхности с естествена дървесна визия: фино рифелувана и гладко структурирана.</p>
                        <p>С Terrace Massive лесно могат да се оформят заоблени краища, без завършващ профил, за да се получи непрекъснат 
                        преход. Профилите са изцяло оцветени – ръбът на отрязаното място е в същия цвят като повърхността.</p>
                        <p>За създаването на органични форми е разработен специален въртящ се клипс. Той позволява монтажа без да е налице прав 
                        ъгъл между профилите и поддържащата конструкция. Така те могат да се нарят свободно, което е много важно при 
                        пространства около басейни, градински пътеки, лехи. Солидните профили могат да се използват от двете страни – 
                        едната фино рифелувана, а другата гладка.</p>
                    </div>
                    <hr></hr>

                    <h5><i>Twinson Terrace Massive Pro</i></h5>
                    <div>
                        <p>Солидни профили с пластмасов покриващ слой за голямо натоварване. Изключителна устойчивост на петна, много лесна 
                        поддръжка и естествена визия с автентичните несиметрични дървесни шарки.</p>
                        <p>Позволява бързо и лесно монтиране със скрита клип система. Налични са различни поддържащи конструкции от алуминий и 
                        Twinson със съответните аксесоари. С ниската си обща структурна височина от едва 36 мм системата е подходяща и за 
                        саниране на балкони и тераси на къщи, апартаменти, офиси. За плаващи конструкции като например покриви на тераси са 
                        възможни височини на монтаж от 70 мм. Почиства се лесно с чиста или сапунена вода и мека четка.</p>
                    </div>
                </div>
            </div>

            <hr></hr>

            <div className="w3-row-padding">
                <h2>Цветовете на Twinson</h2>
                <p>
                    Естествените, красиви цветове на Twinson с възможността за използване и на двете страни на профилите правят оформлението 
                    забавно. Запознайте се с цветовете на Twinson Terrace, Twinson Terrace Massive и Twinson Terrace Massive Pro и дайте 
                    воля на въображението си.
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за палитрата на Twinson" imgSrc={col_icon} href="/col_oblitz" />
                </ul>
            </div>

        </div>
    )
  }
  
  export default Nast_Terasi