import React, { Component } from 'react'

class GalleryBtn extends Component {

    constructor(props){
        super(props)
        this.state = {
            text: props.text,
            segmentId: props.segmentId
        }
    }

    openTab() {
        let tabName = document.getElementById(this.state.segmentId)

        console.log(tabName)

        let i, x
        x = document.getElementsByClassName("tab")

        for (i = 0; i < x.length; i++) {
            x[i].style.display = "none"
        }

        console.log(this.state.segmentId)

        tabName.style.display = "block"
      }

    render() {
        return (
            <button className="w3-bar-item w3-button" onClick={() => this.openTab()}>{this.state.text}</button>
        )
    }
}

class GallerySegment extends Component {

    constructor(props){
        super(props)
        this.state = {
            id: props.id,
            title: props.title,
            content: props.content
        }
    }

    render() {
        return (
            <div id={this.state.id} className="w3-container w3-border tab" style={{display:"none"}}>
                <h2>{this.state.title}</h2>
                <div>{this.state.content()}</div>
            </div>
        )
    }
}

class GalleryTab extends Component {

    constructor(props){
        super(props)
        this.state = {
            itemArr: props.itemArr || [],
            btnArr: props.btnArr || []
        }
    }

    render() {

        const items = []
        const segmentIds = []

        for(let i=0; i<this.state.itemArr.length; i++){
            let s = this.state.itemArr[i]
            items.push(<GallerySegment id={s.id} title={s.title} content={s.content} />)
            segmentIds.push(s.id)
        }

        const buttons = []
        for(let i=0; i<this.state.btnArr.length; i++){
            let s = this.state.btnArr[i]
            buttons.push(<GalleryBtn segmentId={segmentIds[i]} text={s.text} />)
        }

        return (

            <div>

                <div className="w3-bar w3-blue-grey">
                    {buttons}
                </div>

                <div>
                    {items}
                </div>

            </div>
        )
    }

}

export default GalleryTab