import React from 'react'
import cream_white from '../../../images/col/samples/vechni/096_47_cream_white.jpg'
import red from '../../../images/col/samples/vechni/027_88_red.jpg'
import light_grey from '../../../images/col/samples/vechni/907_94_light_grey.jpg'
import wine_red from '../../../images/col/samples/vechni/644_84_wine_red.jpg'
import quartz_grey from '../../../images/col/samples/vechni/068_72_quartz_grey.jpg'
import moss_green from '../../../images/col/samples/vechni/134_67_moss_green.jpg'
import steel_blue from '../../../images/col/samples/vechni/079_78_steel_blue.jpg'
import chocolate_brown from '../../../images/col/samples/vechni/008_13_chocolate_brown.jpg'

function Vechni_Col() {

    return(

        <div className="w3-padding w3-mobile"  style={{display: "block"}}>

            <table class="w3-table w3-bordered w3-padding w3-mobile">
                <tr>
                    <th>Цвят</th>
                    <th>Име</th>
                </tr>
                <tr>
                    <td><img src={cream_white} className="w3-mobile"></img></td>
                    <td>DEC.096/47 Кремаво бяло</td>
                </tr>
                <tr>
                    <td><img src={red} className="w3-mobile"></img></td>
                    <td>DEC.027/88 Червено</td>
                </tr>
                <tr>
                    <td><img src={light_grey} className="w3-mobile"></img></td>
                    <td>DEC.907/94 Светло сиво</td>
                </tr>
                <tr>
                    <td><img src={wine_red} className="w3-mobile"></img></td>
                    <td>DEC.644/84 Винено червено</td>
                </tr>
                <tr>
                    <td><img src={quartz_grey} className="w3-mobile"></img></td>
                    <td>DEC.068/72 Кварцово сиво</td>
                </tr>
                <tr>
                    <td><img src={moss_green} className="w3-mobile"></img></td>
                    <td>DEC.134/67 Мъхесто зелено</td>
                </tr>
                <tr>
                    <td><img src={steel_blue} className="w3-mobile"></img></td>
                    <td>DEC.079/78 Стоманено синьо</td>
                </tr>
                <tr>
                    <td><img src={chocolate_brown} className="w3-mobile"></img></td>
                    <td>DEC.008/13 Шоколадово кафяво</td>
                </tr>
            </table>

        </div>
    )
}

export default Vechni_Col