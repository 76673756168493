import React from 'react'
import terasi_montage1 from '../../images/nastilki/terasi_montage1_resize.jpg'
import terasi_montage2 from '../../images/nastilki/terasi_montage2_resize.jpg'

function Nast_Terasi_Montage() {

    return (

        <div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-padding">
                    <p>Профилите за подово покритие са дъски с две лица, така че може да се избира между по-фин и по груб завършек. 
                    Монтажът е изцяло улеснен. Дъската се реже по размер, фиксира се към рамката с използването на скрита система и се 
                    покрива с Twinson Terrace. Никога досега не е било по-лесно да покриете двора си.</p>
                    <p>Монтажът на дъските се извършва със и без скоби.</p>
                    <p>Сглобяването на елементите става с минимален брой инструменти, а на готовата настилка не се виждат никакви 
                    винтове и гайки.</p>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={terasi_montage1} className="w3-cell w3-padding w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">
                    <p>Дъските могат да се сглобяват с помощта на закрепващи скоби. Монтажът е бърз и сигурен. Устойчивото закрепване дава 
                    възможност на материала да се разширява или свива от температурните промени и влагата.</p>
                    <p>Скобите (P 9544) са направени за употреба с 4 милиметрови фрезенкови винтове и се използват при монтиране в градини 
                    или за вътрешни покрития.</p>
                    <p>В случай че използвате Twinson в близост до море или воден басейн, препоръчват се специални скоби от неръждаема 
                    стомана (P 9554).</p>
                </div>
            </div>

            <div className="w3-cell-row">
                <div className="w3-cell w3-padding">
                    <p>Ако искате да монтирате без да използвате скоби, препоръчително е да използвате винтове от неръждаема стомана.</p>
                    <p>Елементите трябва да бъдат на разстояние 4 мм един от друг. Ако дължината на дъските надвишава 4.5 метра, 
                    препоръчително е те да се срежат и да се оставят повече фуги за разширение. И тук, както при дъските от твърда 
                    дървесина, е необходимо предварително пробиване на отвори за винтовете. Препоръчително е да се използват винтове 
                    с шайби, за да се разпредели натиска по-равномерно върху материала. Не трябва да се слагат винтове на по-малко от 
                    3 см от края.</p>
                    <p>Дъските за подово покритие се предлагат в стандартни дължини от 4.50 и 6 метра.</p>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={terasi_montage2} className="w3-cell w3-padding w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">
                    <p>Важна е подготовката на основата, върху която се подреждат дъските за под. Опитът показва, че сглобяването на скарата 
                    е по-трудоемкия процес, отколкото реденето на дъските.</p>
                    <p>Повърхността, върху която ще се монтират, трябва да бъде устойчива и изравнена, за да се постигне оптимален резултат.</p>
                    <p>С помощта на вътрешни подпори се осигурява необходимата вентилация под вътрешните елементи, като разстоянието между 
                    подпорите е минимум 50 cm.  Ако елементите са монтирани диагонално, най-голямото разстояние е 30 cm.</p>
                    <p>Подовите елементи трябва да се подпират във всеки край. Ако това не е възможно, максималното издаване е 10 cm.</p>
                    <p>Препоръчително е да оставите елементите в легнало положение поне 24 часа преди да монтирате профилите, за да се 
                    приспособят към климатичните условия.  За да не се образуват локви, които могат да замръзнат през зимата, е добре да 
                    наклоните леко вътрешните елементи по продължение на профилите (0.5 mm/m).</p>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <div className="w3-cell w3-padding">
                    <p>Продуктът е с различна степен на устойчивост към препарати и химикали. Силно устойчив е на сапунен разтвор и вода, 
                    концентрирана белина, течни горива, синтетични разтворители и други препарати. Петната се почистват бързо с вода, мек 
                    почистващ продукт и гъба. С годините дъските посивяват и придобиват нюансите на камъка.</p>
                    <p>Профилите Twinson са конструирани с оглед лесно да се демонтират и редят наново. Трябва да се предвиди достатъчно 
                    място за разширението на профилите. Задължително се оставят най-малко 2 мм между дъските, заради температурните 
                    разширения и абсорбирането на влага.</p>
                </div>
            </div>
        </div>
    )
}

export default Nast_Terasi_Montage