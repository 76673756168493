import React from 'react'
import QuickLink from '../components/QuickLink'
import pdf_icon from '../images/icons/pdf_icon.png'

function Fliers() {

    return (
          <div className="w3-padding">
                  <h1>Брошури</h1>
                  
                  <h3 className="w3-margin-left">Easystep</h3>
                  <div className="w3-padding w3-margin-top w3-margin-bottom">
                        <ul className="w3-ul w3-card-4 w3-hoverable">
                              <QuickLink href="/files/fliers/Easystep from Deceuninck.pdf" download={true} text="Easystep" textSmall="from Deceuninck" imgSrc={pdf_icon}></QuickLink>
                        </ul>
                  </div>
                  <hr/>

                  <div className="w3-padding w3-margin-top w3-margin-bottom">
                        <ul className="w3-ul w3-card-4 w3-hoverable">
                              <QuickLink href="/files/fliers/Profile overview Terrace & Terrace+.pdf" download={true} text="Terrace & Terrace+" textSmall="Profile overview" imgSrc={pdf_icon}></QuickLink>
                        </ul>
                  </div>

                  <div className="w3-padding w3-margin-top w3-margin-bottom">
                        <ul className="w3-ul w3-card-4 w3-hoverable">
                              <QuickLink href="/files/fliers/Twinson Cladding.pdf" download={true} text="Twinson" textSmall="Cladding" imgSrc={pdf_icon}></QuickLink>
                        </ul>
                  </div>

                  <div className="w3-padding w3-margin-top w3-margin-bottom">
                        <ul className="w3-ul w3-card-4 w3-hoverable">
                              <QuickLink href="/files/fliers/TwinsonTerrace.pdf" download={true} text="Twinson" textSmall="Terrace" imgSrc={pdf_icon}></QuickLink>
                        </ul>
                  </div>
          </div>
  
    );
  }
  
  export default Fliers