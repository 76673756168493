import React from 'react'
import './App.css'

import CardList from './components/CardList';
import LinkList from './components/LinkList';
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import Footer from './components/Footer'

import About from './navbar_pages/text/about'
import AboutDec from './navbar_pages/text/about_dec'
import Manufacturers from './navbar_pages/text/manufacturers'
import Contact from './navbar_pages/text/contact'
import News from './navbar_pages/text/news'
import Gallery from './navbar_pages/gallery'
import Fliers from './navbar_pages/fliers'

import Video from './main_page_links/video'
import Downloads from './main_page_links/downloads'

import Proz from './sidebar_pages/proz'
import Vrati from './sidebar_pages/vrati'
import Pluzgane from './sidebar_pages/pluzgane'
import Povdigane from './sidebar_pages/povdigane'
import DopProfili from './sidebar_pages/dop_profili'
import Oblitz from './sidebar_pages/oblitz'
import Nastilki from './sidebar_pages/nastilki'
import Obkov from './sidebar_pages/obkov'
import Colours from './sidebar_pages/colours/colours'

import ColPvc from './sidebar_pages/colours/col_pvc'
import ColOblitz from './sidebar_pages/colours/col_oblitz'

const routing = []
routing['/'] = Main

// navbar
routing['/about'] = About
routing['/about_dec'] = AboutDec
routing['/manufacturers'] = Manufacturers
routing['/contact'] = Contact
routing['/news'] = News
routing['/gallery'] = Gallery
routing['/fliers'] = Fliers

// main page links
routing['/video'] = Video
routing['/downloads'] = Downloads

// sidebar
routing['/prozortsi'] = Proz
routing['/vrati'] = Vrati
routing['/pluzgane'] = Pluzgane
routing['/pluzgane-povdigane'] = Povdigane
routing['/dop-profili'] = DopProfili
routing['/oblitsovki'] = Oblitz
routing['/nastilki-ogradi'] = Nastilki
routing['/obkov'] = Obkov
routing['/colours'] = Colours

routing['/col_pvc'] = ColPvc
routing['/col_oblitz'] = ColOblitz

function Main(){
  return (
    <div>
      <CardList />
      <LinkList />
    </div>
  )
}

function App() {

  const component = routing[window.location.href.toString().split(window.location.host)[1]]
  
  return (
    <div className="App">
        <NavBar />
        <SideBar />

        <div id="main">
            {component()}
        </div>

        <Footer />
    </div>
  );
}

export default App;
