import React from 'react'
import QuickLink from '../../components/QuickLink'
import col_icon from '../../images/icons/col_icon.png'

function Colours() {

    return (
      <div className="w3-padding">
        <h1>Цветове</h1>

        <div className="w3-row-padding w3-margin-top w3-margin-bottom">
          <ul className="w3-ul w3-card-4 w3-hoverable">
              <QuickLink text="За PVC профили" textSmall="Индивидуални цветове за всеки вкус" imgSrc={col_icon} href="/col_pvc" />
          </ul>
        </div>   
              
        <div className="w3-row-padding w3-margin-top w3-margin-bottom">
          <ul className="w3-ul w3-card-4 w3-hoverable">
              <QuickLink text="За настилки, огради и облицовки" textSmall="Настилки и плътни огради Twinson, PVC фасади" imgSrc={col_icon} href="/col_oblitz" />
          </ul>
        </div>   
      </div>
    );
  }
  
  export default Colours;