// side bar on all pages

import React, { Component } from 'react';
class SideBar extends Component{
    constructor(props) {
        super(props);
        this.state = {
          href: [
            '/prozortsi',
            '/vrati',
            '/pluzgane',
            '/pluzgane-povdigane',
            '/dop-profili',
            '/oblitsovki',
            '/nastilki-ogradi',
            '/obkov',
            '/colours'
          ]
        };
    }

    w3_open() {
        document.getElementById("main").style.marginLeft = "25%";
        document.getElementById("mySidebar").style.width = "25%";
        document.getElementById("mySidebar").style.display = "block";
        document.getElementById("openNav").style.display = 'none';
    }

    w3_close() {
        document.getElementById("main").style.marginLeft = "0%";
        document.getElementById("mySidebar").style.display = "none";
        document.getElementById("openNav").style.display = "inline-block";
    }

    render() {
        return (
            <div>
                <div className="w3-sidebar w3-bar-block w3-card w3-animate-left" style={{display:"none"}} id="mySidebar">
                    <button className="w3-bar-item w3-button w3-large" onClick={() => {this.w3_close()} }>Затвори &times;</button>
                    <a href={this.state.href[0]} className="w3-bar-item w3-button">Прозорци</a>
                    <a href={this.state.href[1]} className="w3-bar-item w3-button">Врати</a>
                    <a href={this.state.href[2]} className="w3-bar-item w3-button">Плъзгане</a>
                    <a href={this.state.href[3]} className="w3-bar-item w3-button">Плъзгане с повдигане</a>
                    <a href={this.state.href[4]} className="w3-bar-item w3-button">Допълнителни профили и аксесоари</a>
                    <a href={this.state.href[5]} className="w3-bar-item w3-button">Облицовки за фасади и покриви</a>
                    <a href={this.state.href[6]} className="w3-bar-item w3-button">Настилки за тераси и плътни огради</a>
                    <a href={this.state.href[7]} className="w3-bar-item w3-button">Обков</a>
                    <a href={this.state.href[8]} className="w3-bar-item w3-button"><b>Цветове</b></a>
                </div>
                <button id="openNav" className="w3-button w3-large" onClick={() => {this.w3_open()}}>&#9776; Продукти</button>
            </div>
        )
    }
}
export default SideBar;