// footer on all pages

import React, { Component } from 'react'
import footerPic from '../images/cat2.png'

class Footer extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <footer className="w3-margin-top w3-padding w3-bar w3-blue-grey" id="footer">
                {/* <img src={footerPic} className="w3-bar-item w3-circle w3-hide-small" style={{maxWidth: "85px"}}/> */}
                <p>Требел 16, 2019</p>

                <p style={{fontSize:"10px"}}>Icons on this site courtesy of <a href="https://icons8.com/icons/dusk">https://icons8.com/icons/dusk</a></p>
            </footer>
        )
    }
}
export default Footer