import React from 'react'
import QuickLink from '../../components/QuickLink'
import Accordion from '../../components/Accordion'
import Oblitz_Twinson_Montage from './oblitz_twinson_montage'
import col_icon from '../../images/icons/col_icon.png'
import twinson_front from '../../images/oblitz/twinson_front_resize.jpg'
import twinson_building from '../../images/oblitz/twinson_building.jpg'
import twinson_side from '../../images/oblitz/twinson_side_resize.jpg'
import twinson_face from '../../images/oblitz/twinson_face.jpg'
import twinson_panels from '../../images/oblitz/twinson_panels_resize.jpg'
import twinson_window from '../../images/oblitz/twinson_window_resize.jpg'
import twinson_certificate from '../../images/oblitz/twinson_certificate_resize_crop.jpg'

function Oblitz_Twinson() {

    const item = [
        {
          text: "Монтаж",
          content: Oblitz_Twinson_Montage
        }
      ]

    return (

        <div>

            <div className="w3-cell-row">
                <img src={twinson_front} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <img src={twinson_building} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">
                    <h2>Облицовки за фасади и покриви от Twinson</h2>

                    <p>Twinson е иновативен дървонапълнен композитен материал, обединяващ най-доброто от дървото и PVC.</p>
                    <p>Twinson съчетава натуралността на дървото с лесната употреба, трайността и лесната поддръжка на PVC материала. 
                        Материалът е водоустойчив, здрав, гъвкав и с добър външен вид, и това са само някои от преимуществата му.</p>
                    <p>Изработваните от него профили се монтират най-често като фасадни облицовки или подова настилка в двора, градината, 
                        терасата, около басейна или в обществени заведения. Това го прави елегантно и надеждно решение за стилно оформяне 
                        на външното пространство, при това с гарантирана дълготрайност.</p>
                    <p>Twinson може да се рециклира 100%, не замърсява околната среда и е отлична алтернатива на твърдите видове дърво.</p>

                    {/* <hr></hr> */}

                    <p>Профилите от Twinson се предлагат в красиви, естествени цветове. Няма нужда от боядисване, а цветовете запазват чудесната си визия за много дълго време. Лесната поддръжка позволява дълготрайна употреба, а гаранцията е до 25 години.</p>
                    <p>Профилите Twinson могат да се използват и от двете страни. Едната повърхност е грапава, а другата – фино рифелувана, като и двете страни са безопасни спрямо подхлъзване.</p>
                    <p>Системата има две разновидности: <a href="/nastilki-ogradi">Twinson Terrace</a> – за веранди, открити пространства около басейни, огради и вътрешен двор и Twinson Face – предназначена за външни облицовки – от предната фасада до задната стена и от покрива до прозорците.</p>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={twinson_side} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <img src={twinson_face} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">
                    <h2>Twinson Face – стилна фасада в хармония с природата</h2>

                    <p>Twinson Face са профили за фасадни облицовки, които съчетават  естествената красота и качества на дървото с 
                        трайността и гъвкавостта на поливинилхлорида. Произвеждат се няколко типа профили, в зависимост от размерите и 
                        дизайна им.</p>

                    <ul>
                        <li><b>P 9558</b> Профил, който формира плоска повърхност на фасадата. Той е с  прав  U-образен канал,  165 мм използваема 
                            ширина и 13,5 мм дебелина.</li>
                        <li><b>P 9580/1</b> Хоризонтален профил, който създава ефекта на набити дървени дъски.</li>
                        <li><b>P 9553</b> Вертикален профил със скандинавски ефект. Монтира се хоризонтално върху вертикална решетка. Панелите са 
                            с дължина 180 мм и дебелина 20 мм. Съединението е глъб и зъб.</li>
                    </ul>

                    <p>Ламелите са водоустойчиви, не се покриват с мъх и не гният, изглеждат елегантно и естествено, придават хармонична 
                        завършеност на дома.</p>
                    <p>Вертикалните профили са предпочитани за административни сгради, докато хоризонталните се използват предимно за 
                        облицоване на къщи и вили.</p>
                    <p>За затворените системи се предлагат многобройни завършващи профили за страничните части на касите на прозорците и 
                        свързващите елементи на покрива.</p>
                    <p>В системата са интегрирани скари за вентилация. Циркулиращият въздух между външната стена и облицовката на фасадата 
                        отвежда влагата навън, като предпазва самата сграда и се грижи за здравословния климат в помещенията. </p>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={twinson_panels} className="w3-cell w3-padding w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">
                    <h2>Характеристики</h2>
                    
                    <ul>
                        <li>Изключително издръжливи на климатичните влияния и здрави</li>
                        <li>Устойчиви на насекоми</li>
                        <li>Нямат нужда от боядисване</li>
                        <li>Комбинират се перфектно със системи за топлоизолация</li>
                        <li>100% рециклируеми</li>
                        <li>Бърз и лесен монтаж със система за присъединяване тип глъб-зъб</li>
                        <li>Широк набор от завършващи профили</li>
                        <li>Могат да се използват при нови и при стари сгради</li>
                    </ul>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row w3-margin-top">
                <div className="w3-cell w3-padding w3-half">
                    <h5><i>Затворена система за фасадни облицовки</i></h5>
                    <ul>
                        <li>Двустенни профили с дължина 3 метра и 6 метра</li>
                        <li>Ширина на профила от 167 мм</li>
                        <li>Облицовка на външни стени, еркерни прозорци, стрехи, капандури, капчуци</li>
                        <li>Лесен монтаж със скрито закрепване чрез патентована клип система</li>
                        <li>Цялостна система, включително принадлежности и скари за вентилация</li>
                        <li>Възможност за комбиниране със системи за топлоизолация</li>
                        <li>Разнообразни завършващи профили
                            <ul>
                                <li>завършващи профили от Twinson (Лакрицово черно, Дървесно кафяво, Шистово сиво)</li>
                                <li>елоксирани алуминиеви профили в черно, сребърно, кафяво</li>
                            </ul> 
                        </li>
                        <li>Отговарят на изискванията за клас по реакция на огън В2</li>
                    </ul>
                </div>
                <div className="w3-cell w3-padding w3-half">
                    <h5><i>Отворена система за фасадни облицовки със скосени/плоски профили</i></h5>
                    <ul>
                        <li>Профили с дължина от 3 м</li>
                        <li>Ширина на профила от 78 мм</li>
                        <li>Видими фуги за съвременно оформление на фасадата</li>
                        <li>Поддържаща конструкция от черен елоксиран алуминий</li>
                        <li>Вентилируема фасада</li>
                    </ul>
                </div>
            </div>

            <hr></hr>
            
            <div className="w3-cell-row">
                <Accordion sectionArr={item}></Accordion>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={twinson_certificate} className="w3-cell w3-padding w3-mobile"></img>
                <div className="w3-cell w3-padding">
                    <h2>Сертификат по PEFC</h2>
                    <p>Патентованият материал Twinson е екологичносъобразен и пести ресурси, а като биоматериал е истинска алтернатива на 
                        дървесните тропически видове. Печатът на PEFC гарантира, че дървесината, използвана за създаването на Twinson, 
                        произлиза от устойчиво горско стопанство и запазва биоразнообразието на горите. Всички продукти са 100% рециклируеми 
                        в края на техния полезен живот.</p>
                </div>
            </div>

            <hr></hr>

            <div className="w3-row-padding">
                <h2>Цветове за индивидуален дизайн</h2>
                <p>
                    Профилите за облицовка на фасадите и стрехите от Twinson, наподобяващи естествено дърво, се предлагат в три различни 
                    красиви цвята. По този начин хармоничното цветово съчетаване с градината, терасата и оградата е гарантирано. 
                    Запознайте се с цветовете на Twinson за настилки, огради, фасади и покриви.
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за палитрата на Twinson" imgSrc={col_icon} href="/col_oblitz" />
                </ul>
            </div>

        </div>
    )
}

export default Oblitz_Twinson