import React from 'react'

function Manufacturers() {

      return (
            <div className="w3-padding">
            <h1>Производители</h1>
            <div className="w3-padding">
                  <div className="w3-card w3-padding  w3-center" style={{width:"18em"}}>
                        <p><b>СОЛАРС - 75 ЕООД</b></p>
                        <p>0888 922959</p>
                        <a href="mailto:office@ggeorgiev.com">officem@solars-75.com</a>            
                        <p>БУРГАС</p> 
                  </div>
                  <div className="w3-cell">&nbsp;</div>
                  <div className="w3-card w3-padding  w3-center" style={{width:"18em"}}>
                        <p><b>ЕН ДЖИ СТИЛ ООД</b></p>
                        <p>088 824 7783</p>
                        <a href="mailto:office@ggeorgiev.com">office@ggeorgiev.com</a>            
                        <p>ПЛОВДИВ</p> 
                  </div>
                  <div className="w3-cell">&nbsp;</div>
                  <div className="w3-card w3-padding  w3-center" style={{width:"18em"}}>
                        <p><b>РИД 09 ЕООД</b></p>
                        <p>088 825 3612</p>
                        <a href="mailto:rid09@abv.bg">rid09@abv.bg</a>            
                        <p>БЕРКОВИЦА</p> 
                  </div>
                  <div className="w3-cell">&nbsp;</div>
                  <div className="w3-card w3-padding  w3-center" style={{width:"18em"}}>
                        <p><b>СТАЛИА ООД</b></p>
                        <p>089 799 2099</p>
                        <a href="mailto:stalia@abv.bg">stalia@abv.bg</a>
                        <p>БЕРКОВИЦА</p> 
                  </div>
                  {/* <div className="w3-cell">&nbsp;</div>
                  <div className="w3-card w3-padding  w3-center" style={{width:"18em"}}>
                        <p><b>ШУМАНА 83 ЕООД</b></p>
                        <p>089 799 5863</p> 
                        <a href="mailto:shumana_83@abv.bg">shumana_83@abv.bg</a>
                        <p>ПАНАГЮРИЩЕ</p> 
                  </div> */}
                  <div className="w3-cell">&nbsp;</div>
                  <div className="w3-card w3-padding  w3-center" style={{width:"18em"}}>
                        <p><b>СД ШУМАНА</b></p>
                        <p>089 567 3785</p> 
                        <a href="mailto:shumana_90@abv.bg">shumana_90@abv.bg</a>
                        <p>ПАНАГЮРИЩЕ</p> 
                  </div>
            </div>
      </div>
    )
  }
  
  export default Manufacturers