// top bar on all pages

import React, { Component } from 'react'

import logo1 from '../images/cat2.png'

class NavBar extends Component{
    constructor(props) {
        super(props);
        this.state = {
          href: [
              "/",
              "/about",
              "/about_dec",
              "/manufacturers",
              "/contact",
              "/news",
              "/gallery",
              "/fliers"
          ]
        };
    }

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    stickify() {
        // Get the header
        let header = document.getElementById("navbar");

        // Get the offset position of the navbar
        let sticky = header.offsetTop;

        if (window.pageYOffset > sticky)
            header.classList.add("sticky")
        else
            header.classList.remove("sticky")
    }

    render() {
        // When the user scrolls the page, execute function 
        // window.onscroll = this.stickify

        return (
            <div className="w3-bar w3-blue-gray header" id="navbar" style={{zIndex:"5000"}}>
                {/* <img src={logo1} className="w3-bar-item w3-circle w3-hide-small" style={{maxWidth: "60px"}}/>
                <img src={logo1} className="w3-bar-item w3-circle w3-hide-small" style={{maxWidth: "60px"}}/> */}
                <a href={this.state.href[0]} className="w3-bar-item w3-button">&#8962; Home</a>
                <a href={this.state.href[1]} className="w3-bar-item w3-button">За нас</a>
                <a href={this.state.href[2]} className="w3-bar-item w3-button">За Deceuninck</a>
                <a href={this.state.href[3]} className="w3-bar-item w3-button">Производители</a>
                <a href={this.state.href[4]} className="w3-bar-item w3-button">Контакти</a>
                <a href={this.state.href[5]} className="w3-bar-item w3-button">Новини и промоции</a>
                <a href={this.state.href[6]} className="w3-bar-item w3-button">Галерия и видео</a>
                <a href={this.state.href[7]} className="w3-bar-item w3-button">Брошури</a>
            </div>
        )
    }
}

export default NavBar