import React from 'react'
import ModalBoxBtn from '../../../components/ModalBoxBtn'
import col from '../../../images/col/samples/twinson-pvc/nastilki_tw_and_tw_massive.jpg'

function OgradiTw_Col() {

    return(

        <div className="w3-mobile">
            <img src={col} style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%"}}/>
        </div>
    )
}

function OgradiTw() {

    return(

        <div className="w3-cell-row">
            <div className="w3-cell w3-padding w3-mobile" style={{width: "60%"}}>
                <p>
                    Като елементи на плътната ограда между стълбовете могат да се използват профили от системата Twinson Terrace. Видът 
                    им наподобява естествено дърво, в шест различни цвята, с два вида повърхности – грапава и фино рифелувана. По този 
                    начин можете да съчетаете хармонично оградата с градината и терасата. 
                </p>
            </div>
            <div className="w3-cell w3-padding w3-mobile" style={{verticalAlign: "middle"}}>
                <ModalBoxBtn text="Разгледайте палитрата" id="ogradiTw" component={OgradiTw_Col}/>
            </div>
        </div>
    )
}

export default OgradiTw