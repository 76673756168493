import React from 'react'
import QuickLink from '../../components/QuickLink'

import vr_elegante_cover from '../../images/vrati/vr_elegante_cover_crop.jpg'
import vrati_elegante_house from '../../images/vrati/vrati_elegante_house.jpg'
import elegante_cross_sec from '../../images/vrati/elegante_cross_sec.jpg'
import elegante_mm from '../../images/vrati/elegante_mm.jpg'
import elegante_scheme from '../../images/vrati/elegante_scheme.jpg'

import col_icon from '../../images/icons/col_icon.png'

function Vrati_Elegante() {

    return(

        <div>

            <h1 className="w3-padding">ВРАТИ ELEGANTE</h1>
            <h2 className="w3-padding">Елегантен дизайн с алуминиев външен вид, съчетан с изключителни технически характеристики</h2>
            <div className="w3-cell-col">
                <img src={vrati_elegante_house} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-padding">
                    <p>През есента на 2018 г. Deceuninck представи PVC профила с най-тънкия “фалц“ на пазара (само 9 мм) и така стана 
                    пионер в съчетаването на стилния дизайн с висококачествено техническо изпълнение. Профилът Elegante е напълно съвместим 
                    със системите за врати <a href="/pluzgane-povdigane">Плъзгане с повдигане</a>, както и с профилите Eforte за крилата 
                    на входните врати.</p>
                    <p>Elegante от Deceuninck спечели наградата Red Dot 2019 в категорията „Продуктов дизайн“. Наградата Red Dot съществува 
                    от 1954 г. и се смята за една от най-престижните европейски награди за дизайн.</p>
                    <p>Elegante е алтернатива на алуминиевите профили, но с изключителни стойности на топлоизолация. Системата е високо 
                    екологична, тъй като ядрото EcoPowerCore на профила е изработено от рециклиран PVC. Изпълнява се в два варианта:  
                    с прави или с изпъкнали крила.</p>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row w3-padding">
                <h2>Вarrier-free thresholds</h2>
                <p>Вратите Elegante позволяват да се правят прагове без бариери, тъй като конструкцията има специален фиксатор, 
                който издърпва уплътнението, когато вратата се затваря, предотвратявайки топлинните загуби и течения, и я поставя, 
                когато вратата се отвори.</p>
            </div>
            
            <hr></hr>

            <div className="w3-cell-row w3-padding">
                    <h2>Характеристики</h2>      
                    <ul>
                        <li>Стойност на Uf = 0,93 W / m²K със стандартна стоманена армировка и без допълнителна изолация</li>
                        <li>Дълбочина само 76 мм с остъкляване до 70 мм</li>
                        <li>С дълбочина 76 мм, тази шест-камерна профилна система отговоря на всички потребителски изисквания</li>
                        <li>Правоъгълни остъклителни лайсни за лесно остъкляване</li>
                        <li>Възможност за остъкляване със самозалепващи ленти или с лепило за максимални размери на елементите и защита 
                            срещу взлом клас RC2</li>
                        <li>Използване на универсална армировка, отговаряща на различните комбинации от профили</li>
                    </ul>
            </div>

            <div className="w3-cell-row w3-mobile">
                <img src={elegante_cross_sec} className="w3-cell w3-third w3-padding w3-mobile"></img>
                <img src={elegante_mm} className="w3-cell w3-third w3-padding w3-mobile"></img>
                <img src={elegante_scheme} className="w3-cell w3-third w3-padding w3-mobile"></img>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={vr_elegante_cover} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-padding w3-mobile">
                    <h2>Устойчивост</h2>
                    <p>Този профил е 100% рециклируем и произведен с рециклирани елементи. Това е двойно рециклиране. Със срок на 
                    експлоатация 50 години и възможността да бъде рециклиран до 10 пъти, без да губи своите характеристики, същият PVC 
                    може да служи като основен материал до 500 години! Deceuninck не се колебае по пътя към устойчив дизайн!</p>
                    <ul>
                        <li>Технология <i>EcoPowerCore</i></li>
                        <li>Ядрото <i>EcoPowerCore</i> е изработено от рециклиран PVC. Стремеж към затворен цикъл на производство</li>
                        <li><i>EcoPowerCore</i> не се вижда, но подобрява механичните свойства на профила</li>
                        <li>Отлична стойност на топлинна пропускливост Uf = 0.93 W / m²K - идеално решение за пасивни и нискоенергийни сгради</li>
                    </ul>
                </p>
            </div>

            <hr></hr>

            <div>
                <p className="w3-padding">
                    <h2>Богат избор на цветове за индивидуално оформление</h2>
                    Можете да избирате между 40 различни декора за вашите врати. Палитрата обхваща универсални цветове, декорации с вид на 
                    дърво, повърхности с перлена структура или с вид на алуминий. 
                    Цветовете са групирани по архитектурни стилове, за да отговорят на всеки индивидуален вкус.
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за цветовата програма на Декьонинк" imgSrc={col_icon} href="/col_pvc" />
                </ul>
            </div>

        </div>
    )
}

export default Vrati_Elegante