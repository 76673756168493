import React from 'react'
import classic_antracit from '../../../images/col/classic_antracit.jpg'
import classic_oreh from '../../../images/col/classic_oreh.jpg'
import ModalBoxBtn from '../../../components/ModalBoxBtn'
import Classic_Col from './classic_col'

function Classic() {
    return (
        <div>
            <div>
                <h5>Нашите най-продавани цветни декори в дървесни нюанси отлично допълват архитектурата на жилищни и на административни сгради.</h5>
                <p>
                    С елегантните си несиметрични дървесни шарки Класическите цветове подхождат на различни архитектурни стилове и са универсално 
                    приложими за жилищни и за административни сгради. Отличават се с висока устойчивост на цвета и дълъг живот.
                </p>
            </div>

            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile" style={{width: "60%"}}>
                    <div className="w3-padding" style={{display: 'inline-block'}}>
                        <img src={classic_antracit}/>
                        <p>Класически цветове / Антрацит</p>
                    </div> 
                    <div className="w3-padding" style={{display: 'inline-block'}}>
                        <img src={classic_oreh}/> 
                        <p>Класически цветове / Орех</p>
                    </div>
                </div>
                <div className="w3-cell w3-padding w3-mobile" style={{verticalAlign: "middle"}}>
                    <ModalBoxBtn text="Разгледайте палитрата" id="classic" component={Classic_Col}/>
                </div>
            </div>
        </div>
    )
}

export default Classic