// image cards on the main page

import React, { Component } from 'react';
class ImgCard extends Component{
    constructor(props) {
        super(props);
        this.state = {
          imgPath: props.imgPath,
          text: props.text,
          href: props.href,
          id: props.id
        };
    }

    render() {
        return (
            <a id={this.state.id} className="w3-col w3-third w3-padding" href={this.state.href} style={{textDecoration: 'none'}}>
                <div className="w3-card w3-hover-blue-grey">
                    <img src={this.state.imgPath} alt={this.state.text} style={{width:'100%'}}/>
                    <div className="w3-container">
                        <h5>{this.state.text}</h5>
                    </div>
                </div>
            </a>
        )
    }
}
export default ImgCard;