import React from 'react'
import ModalBoxBtn from '../../../components/ModalBoxBtn'
import col from '../../../images/col/samples/twinson-pvc/nastilki_tw_and_tw_massive.jpg'
import col_pro from '../../../images/col/samples/twinson-pvc/nastilki_tw_pro.jpg'

function NastilkiTw_Col() {

    return(

        <div className="w3-mobile">
            <img src={col} style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%"}}/>
        </div>
    )
}

function NastilkiTwPro_Col() {

    return(

        <div className="w3-mobile">
            <img src={col_pro} style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%"}}/>
        </div>
    )
}

function NastilkiTw() {

    return (

        <div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-padding w3-mobile" style={{width: "60%"}}>
                    <h2>Twinson Terrace / Twinson Terrace Massive</h2>
                    <p>
                        <b>Twinson Terrace</b> е подходяща за всякаква употреба навън и се вписва хармонично в пейзажа благодарение на шест 
                        естествени, красиви цвята. Имате избор от две повърхности: грапава и фино рифелувана.
                    </p>
                    <p>
                        <b>Twinson Terrace Massive</b> е компактна настилка, идеална за извити форми. Тази здрава настилка няма камери и 
                        може да се реже като дърво. Налична е с две различни повърхности с естествена дървесна визия: фино рифелувана и 
                        гладко структурирана. С Terrace Massive лесно могат да се оформят заоблени краища, без завършващ профил, за да се 
                        получи непрекъснат преход. Профилите са изцяло оцветени – ръбът на отрязаното място е в същия цвят като повърхността.
                    </p>
                    <p>
                        Настилките и завършващите профили, изработени от Twinson Terrace и Twinson Terrace Massive, могат да бъдат оцветени в 
                        шест цвята, с две различни използваеми повърхности с естествена дървесна визия. 
                    </p>
                    <p>
                        Цветът на настилките се стабилизира под въздействието на атмосферните условия.
                    </p>
                </div>
                <div className="w3-cell w3-padding w3-mobile" style={{verticalAlign: "middle"}}>
                    <ModalBoxBtn text="Разгледайте палитрата" id="nastilkiTw" component={NastilkiTw_Col}/>
                </div>
            </div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-padding w3-mobile" style={{width: "60%"}}>
                    <h2>Twinson Terrace Massive Pro</h2>
                    <p>
                        <b>Twinson Terrace Massive Pro</b> са солидни профили с пластмасов покриващ слой за голямо натоварване. 
                        Изключителна устойчивост на петна, много лесна поддръжка и естествена визия с автентичните несиметрични дървесни 
                        шарки. Без да избледняват, четирите автентични дървесни цвята на Twinson Terrace Massive Pro остават свежи през 
                        годините. Естественият облик е допълнен от релефа, който свойствата на дървото моделират, и от природния цветен 
                        градиент с два нюанса – антично дъбово кафяво и опушено дъбово сиво.
                    </p>
                </div>
                <div className="w3-cell w3-padding w3-mobile" style={{verticalAlign: "middle"}}>
                    <ModalBoxBtn text="Разгледайте палитрата" id="nastilkiTwPro" component={NastilkiTwPro_Col}/>
                </div>
            </div>
        </div>
    )
}

export default NastilkiTw