import React from 'react'
import GalleryImg from '../../components/GalleryImg'

import arcade_front from '../../images/gallery/vrati/arcade_front.jpg'
import arcade_inside from '../../images/gallery/vrati/arcade_inside.jpg'
import eforte_front from '../../images/gallery/vrati/eforte_front.jpg'
import eforte_inside from '../../images/gallery/vrati/eforte_inside.jpg'

import arcade from '../../images/gallery/vrati/arcade.jpg'
import eforte from '../../images/gallery/vrati/eforte.jpg'
import elegante from '../../images/gallery/vrati/elegante.jpg'

import arcade_bed from '../../images/gallery/vrati/arcade_bed.jpg'
import eforte_room from '../../images/gallery/vrati/eforte_room.jpg'
import eforte_house from '../../images/gallery/vrati/eforte_house.jpg'

function GalleryVrati() {

    return (

        <div>

            <h3 className="w3-padding"><i><a href="/vrati">Към врати &#128279;</a></i></h3>
            
            <div className="w3-row-padding">
                <div className="w3-quarter">
                    <GalleryImg imgId="img2" modalId="modal2" image={arcade_front} text="Входна врата Arcade"></GalleryImg>
                </div>
                <div className="w3-quarter">
                    <GalleryImg imgId="img2" modalId="modal2" image={arcade_inside} text="Arcade отвътре"></GalleryImg>
                </div>
                <div className="w3-quarter">
                    <GalleryImg imgId="img2" modalId="modal2" image={eforte_front} text="Входна врата Eforte"></GalleryImg>
                </div>
                <div className="w3-quarter">
                    <GalleryImg imgId="img2" modalId="modal2" image={eforte_inside} text="Eforte отвътре"></GalleryImg>
                </div>
            </div>

            <hr></hr>

            <div className="w3-row-padding">
                <div className="w3-third">
                    <GalleryImg imgId="img2" modalId="modal2" image={arcade} text="Arcade"></GalleryImg>
                </div>
                <div className="w3-third">
                    <GalleryImg imgId="img2" modalId="modal2" image={eforte} text="Eforte"></GalleryImg>
                </div>
                <div className="w3-third">
                    <GalleryImg imgId="img2" modalId="modal2" image={elegante} text="Elegante"></GalleryImg>
                </div>
            </div>

            <hr></hr>

            <div className="w3-row-padding">
                <div className="w3-third">
                    <GalleryImg imgId="img2" modalId="modal2" image={arcade_bed} text="Спалня с Arcade"></GalleryImg>
                </div>
                <div className="w3-third">
                    <GalleryImg imgId="img2" modalId="modal2" image={eforte_room} text="Стая с Eforte"></GalleryImg>
                </div>
                <div className="w3-third">
                    <GalleryImg imgId="img2" modalId="modal2" image={eforte_house} text="Сграда с Eforte"></GalleryImg>
                </div>
            </div>

            <hr></hr>

            <div id="modal2" style={{display: "none"}} className="w3-modal" onClick={() => { document.getElementById("modal2").style.display = 'none' }}>
                <span className="w3-button w3-hover-red w3-xlarge w3-display-topright">&times;</span>
                <div className="w3-modal-content w3-animate-zoom">
                    <img alt="cat" id="img2" style={{ width: "100%" }} />
                </div>
            </div>

        </div>
    )
}

export default GalleryVrati