import React from 'react'
import '../App.css'
import ReactPlayer from 'react-player'

import QuickLink from '../components/QuickLink'

import pic_icon from '../images/icons/pic_icon.png'

function Video() {

    return (
        <div className="w3-padding">
            <h1>Видео</h1>

            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile w3-padding">
                    <h4>Easystep 1</h4>
                    <ReactPlayer url="https://www.youtube.com/watch?v=z-tpi3-k5cM&t=5s" className="w3-mobile"/>
                </div>
                <div className="w3-cell w3-mobile w3-padding">
                    <h4>Easystep 2</h4>
                    <ReactPlayer url="https://www.youtube.com/watch?v=Pnv4UMW0vS4" className="w3-mobile"/>
                </div>
            </div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile w3-padding">
                    <h4>Twinson фасадни обицовки</h4>
                    <ReactPlayer url="https://www.youtube.com/watch?v=VuG0g2ueERU&t=12s" className="w3-mobile"/>
                </div>
                <div className="w3-cell w3-mobile w3-padding">
                    <h4>Twinson подови настилки</h4>
                    <ReactPlayer url="https://www.youtube.com/watch?v=UrI-FwevS2s" className="w3-mobile"/>
                </div>
            </div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile w3-padding">
                    <h4>Монтаж на стъклодържател</h4>
                    <ReactPlayer url="https://www.youtube.com/watch?v=Uo4DyacuDR0" className="w3-mobile"/>
                </div>
                <div className="w3-cell w3-mobile w3-padding">
                    <h4>Плъзгане с повдигане</h4>
                    <ReactPlayer url="https://www.youtube.com/watch?v=TDdJX-xZmj0" className="w3-mobile"/>
                </div>
            </div>
            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile w3-padding">
                    <h4>Плъзгане</h4>
                    <ReactPlayer url="https://www.youtube.com/watch?v=M15nd4-HD7g" className="w3-mobile"/>
                </div>
                <div className="w3-cell w3-mobile w3-padding">
                    <h4>Система за плъзгащи се прозорци</h4>
                    <ReactPlayer url="https://www.youtube.com/watch?v=yjZhIJojRCA" className="w3-mobile"/>
                </div>
            </div>
            <div className="w3-cell-row">
                <div className="w3-cell-middle w3-mobile w3-padding">
                    <h4>Хармоника</h4>
                    <ReactPlayer url="https://www.youtube.com/watch?v=_hcR9JDxkm0" className="w3-mobile"/>
                </div>
            </div>

            <div className="w3-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Галерия" textSmall="Снимки" imgSrc={pic_icon} href="/gallery" />
                </ul>
            </div>

        </div>
    );
  }
  
  export default Video;