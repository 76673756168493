import React from 'react'
import modern_baz_sivo from '../../../images/col/modern_baz_sivo.jpg'
import modern_sig_sivo from '../../../images/col/modern_sig_sivo.jpg'
import ModalBoxBtn from '../../../components/ModalBoxBtn'
import Moderni_Col from './moderni_col'

function Moderni() {
    return (
        <div>
            <div>
                <h5>Модерните сиви тонове с елегантна перлена структура придават изискан акцент на архитектурния дизайн.</h5>
                <p>
                    Trend фолиото Alux DB 703 има модерна визия на прахово боядисан алуминий. В комбинация с прозоречните профили в антрацит, 
                    напълно се наподобява вида на алуминия, като към това се прибавят и перфектните топлоизолационнни качества на PVC.
                </p>
            </div>

            <div className="w3-cell-row">
                <div className="w3-cell w3-mobile" style={{width: "60%"}}>
                    <div className="w3-padding" style={{display: 'inline-block'}}>
                        <img src={modern_baz_sivo}/>
                        <p>Модерни цветове / Базалтово сиво гладко</p>
                    </div> 
                    <div className="w3-padding" style={{display: 'inline-block'}}>
                        <img src={modern_sig_sivo}/> 
                        <p>Модерни цветове / Сигнално сиво гладко</p>
                    </div>
                </div>
                <div className="w3-cell w3-padding w3-mobile" style={{verticalAlign: "middle"}}>
                    <ModalBoxBtn text="Разгледайте палитрата" id="moderni" component={Moderni_Col}/>
                </div>
            </div>
        </div>
    )
}

export default Moderni