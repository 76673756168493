import React from 'react'

function Oblitz_PVC_Montage() {

    return (

        <div>

            <div className="w3-row-padding">
                <h5><i>Хоризонтален и вертикален монтаж</i></h5>
                <p>Затворените облицовъчни профили от PVC могат да се поставят както хоризонтално, така и вертикално. Завършващите профили 
                    от алуминий могат да бъдат в цветовете на фасадните елементи, така че да се вписват хармонично в цялостната визия. 
                    Специални завършващи профили покриват вертикалните шевове в зидарията и шевовете, позволяващи движението на елементите 
                    на конструкцията вследствие на температурните промени.</p>
            </div>

        </div>
    )
}

export default Oblitz_PVC_Montage