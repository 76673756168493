import React from 'react'
import QuickLink from '../../components/QuickLink'
import Accordion from '../../components/Accordion'
import col_icon from '../../images/icons/col_icon.png'
import NastilkiTw from './text/nastilki_tw'
import OgradiTw from './text/ogradi_tw'
import FasadiTw from './text/fasadi_tw'
import FasadiPVC from './text/fasadi_pvc'

function ColOblitz() {

  const accItems = [
    {
      text: "Цветове за настилки Twinson",
      content: NastilkiTw
    },
    {
      text: "Цветове за плътни огради Twinson",
      content: OgradiTw
    },
    {
      text: "Цветове за фасади и покриви Twinson",
      content: FasadiTw
    },
    {
      text: "Цветове за фасади от PVC",
      content: FasadiPVC
    }
  ]

    return (
      <div className="w3-padding">
        <h1>Цветове за настилки, огради и облицовки</h1>

        <Accordion sectionArr={accItems}></Accordion>

        <div className="w3-row-padding w3-margin-top w3-margin-bottom">
          <ul className="w3-ul w3-card-4 w3-hoverable">
              <QuickLink text="Обратно" textSmall="към цветове" imgSrc={col_icon} href="/colours" />
          </ul>
        </div>       
      </div>
    );
  }
  
  export default ColOblitz