import React from 'react'

function DopProfili() {

    return (
      <div className="w3-padding">
        <h1>Допълнителни профили и аксесоари</h1>  
        <p>
          <h3>Допълнителни профили</h3>
          <p>1. Подпрозоречен покриващ профил – 1035</p>
          <p>2. Подпрозоречен профил - 367</p>
          <p>3. Перваз</p>
          <p>4. Самозалепваща шпроса – 14646, 15058</p>
          <p>5. Водобран – 15215</p>
        </p>
        <p>
          <h3>Аксесоари</h3>
          <p>Цветен молив – 373</p>
          <p>CLEAN-UP почистващ препарат – 956</p>
          <p>DECO – CLEAN – 961</p>
          <p>Лепило DECO – COLL - 962</p>
        </p>
      </div>
    );
  }
  
  export default DopProfili;