import React from 'react'

function AboutDec() {

    return (
      <div className="w3-padding">
        <h1>За Deceuninck</h1>
        
        <div>
          <p>„Декьонинк Груп“ (Deceuninck Group) принадлежи към топ 3 производителите на профилни
            системи за прозорци и строителни продукти от PVC в Европа и работи активно в 91 държави.
            Deceuninck разчита на природосъобразното строителство. На базата на основните технологии за
            екструдиране на PVC и патентования материал Twinson, компанията създава иновативни
            продукти за прозорци и врати, конструкции за тераси и градини, облицовки за фасади и
            покриви, както и такива за интериора. Решенията й са непреходни, с елегантeн дизайн и
            същевременно с отлична оценка на жизнения цикъл.</p>
          <p>Deceuninck спомага за изграждане на устойчиви, нискоенергийни сгради. Продуктите на
            Deceuninck са в съответствие с корпоративната философия за затворен производствен цикъл. В
            края на полезния им живот, след над 50 години употреба, изделията са напълно рециклируеми
            и е възможно да се използват отново.</p>
          <p>Deceuninck Group оперира в пет региона: Северозападна Европа, Югоизточна Европа,
            Централна и Източна Европа, Турция и САЩ. Десетте компании и трите завода в Европа са
            най-важната част от групата.</p> 
        </div>

        <hr></hr>
        
        <h2 className="w3-padding">История на „Декьонинк Груп“</h2>
        <ul>
          <li>1956 г.	Компанията Kunststoffwerke Gebr. е създадена от братята Ангер в гр. Боген, Германия, за производство на 
          пластмасови тръби. Нейните традиции следва днес Deceuninck Group.</li>
          <li>1975 г.	Започва производството на пластмасови профилни системи за прозорци и врати.</li>
          <li>1985 г.	Името на компанията се променя на Thyssen Polymer GmbH, Kunststofftechnick.</li>
          <li>1995 г.	Thyssen придобива Vinyl Building Products Ltd. в САЩ.</li>
          <li>1998 г.	Основана е Thyssen Polymer (Thai) Co., Банкок.</li>
          <li>2003 г.	Сливане на Thyssen Polymer чрез Deceuninck N. V., Белгия.</li>
          <li>2005 г.	Основава се дъщерното дружество Deceuninck d. o. o., Хърватия.</li>
          <li>2006 г.	Thyssen Polymer представя новата си марка Inoutic – Thyssen Polymer става Inoutic</li>
          <li>2007 г.	Промяна на името на компанията на Inoutic/Deceuninck GmbH</li>
          <li>2010 г.	Въвеждане на първокласната профилна система Eforte с невероятната Uf стойност от 0,95 W/m2K.</li>
          <li>2012 г.	Въвеждане на марката Inoutic в Централна и Източна Европа.</li>
          <li>2019 г.	Въвеждане на марката Deceuninck за целия европейски пазар, включително за Централна и Източна Европа, и 
          отпадане на марката Inoutic.</li>
        </ul>
      </div>
    );
  }
  
  export default AboutDec;