import React from 'react'
import ModalBox from '../components/ModalBox'
import terasi_balcony from '../images/nastilki/terasi_balcony_crop.jpg'
import ogradi_outside from '../images/nastilki/ogradi_outside.jpg'
import Nast_Terasi from './text/nast_terasi.js'
import Nast_Ogradi from './text/nast_ogradi.js'

function ComponentTextTerasi() {
  return (
    <div>
      <h4>Настилки за тераси от TWINSON</h4>
      <h6><i>Компактна WPC настилка, идеална за извити форми</i></h6>
    </div>
  )
}

function ComponentTextOgradi() {
  return (
    <div>
      <h4>Плътни огради от TWINSON</h4>
      <h6><i>Уединение</i></h6>
    </div>
  )
}

function Nastilki() {

    return (
      <div className="w3-padding">
        <h1>Настилки за тераси и плътни огради</h1> 

        <ModalBox id="terasi" imgPath={terasi_balcony} textComp={ComponentTextTerasi} text="Terasi" component={Nast_Terasi}/>        
        <ModalBox id="ogradi" imgPath={ogradi_outside} textComp={ComponentTextOgradi} text="Ogradi" component={Nast_Ogradi}/>        
      </div>
    );
  }
  
  export default Nastilki;