import React from 'react'
import QuickLink from '../../components/QuickLink'

import vrati_arcade_front from '../../images/vrati/vrati_arcade_front_crop.jpg'
import vrati_arcade_bed from '../../images/vrati/vrati_arcade_bed_resize.jpg'
import vrati_arcade_room from '../../images/vrati/vrati_arcade_room_resize_crop.jpg'

import col_icon from '../../images/icons/col_icon.png'

function Vrati_Arcade() {

    return(

        <div>

            <h1 className="w3-padding">ВРАТИ ARCADE</h1>
            <div className="w3-cell-row">
                <img src={vrati_arcade_bed} className="w3-cell w3-padding w3-mobile"></img>
                <p className="w3-cell w3-padding w3-mobile">
                    <h2>Елегантна врата, предлагаща комфорт, сигурност и богати възможности за избор на дизайн</h2>
                    <p className="w3-margin-top">Arcade - 4-камерна профилна система за врати е практично и надеждно решение за входната и балконската врата, дори при 
                    ограничен бюджет. С 20-градусавите си скосявания Arcade придава елегантност на входните PVC врати и предлага многобройни 
                    варианти за индивидуално оформление. Отличава се с издръжливост, сигурност, комфорт и добри топлоизолационни качества.</p>
                </p>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={vrati_arcade_room} className="w3-padding w3-mobile"></img>
                <img src={vrati_arcade_front} className="w3-padding w3-mobile"></img>
                
                <p className="w3-cell w3-padding w3-mobile">
                    <h2 className="w3-margin-left">Характеристики</h2>      
                    <ul>
                        <li>Дълбочина на крилото от 71 мм</li>
                        <li>4-камерна технология</li>
                        <li>Коефициент на топлопреминаване на рамката до Uf = 1,6 W/m2K</li>
                        <li>Панели и стъклопакети с дебелина до 47 мм</li>
                        <li>20-градусови скосявания</li>
                        <li>Солидна конструкция на рамката и много висока устойчивост против изкривяване чрез стоманена армировка 
                            с дебелина от 2,5 мм, позволяваща да се изпълняват и елементи с по-големи размери</li>
                        <li>Метално подсилени ъгли като опция: отлети ъглови скоби от цинк за засилена устойчивост и стабилност</li>
                        <li>Възможност за интегрирано многоточково заключване и специални приспособления за много високо ниво на охрана 
                            в съответствие с DIN-EN 1627</li>
                        <li>Вътрешен двустъпков стъклодържач за по-добра охрана против крадци</li>
                        <li>Висока издръжливост: около 100 000 цикъла на отваряне и затваряне, или около 20 години експлоатационен живот</li>
                        <li>Лесен за преминаване праг в съответствие с DIN 18040 с височина от 20 мм и термично разделяне, изработен от алуминий</li>
                        <li>Второ допълнително уплътнение в областта на прага и ъглите за по-висока устойчивост на вятър и водонепроницаемост</li>
                        <li>Висок комфорт на работа чрез поставянето на брави с размер на щифта до 45 мм</li>
                        <li>Висока степен на интеграция във всички общи типове входни врати: с единични и двойни крила, с френски дизайн, 
                            плъзгащи се, със странични елементи и оберлихти, отваряне навътре или навън</li>
                        <li>Предлагат се в 40 цвята</li>
                    </ul>

                    <hr></hr>
 
                    <p className="w3-padding">
                        <h2>Сигурност и устойчивост в продължение на 20 години</h2>
                        <p>Входните PVC врати Arcade са с доказано качество, с приблизително 100 000 цикъла на отваряне и затваряне, 
                        съответстващи на около 20 години експлоатационен живот.</p>
                        <p>Солидната конструкция на рамките на входните PVC врати е подсилена със стоманена армировка с дебелина от 2,5 мм, 
                        както и със заздравени ъгли посредством отлети ъглови скоби от цинк. Включването на многоточково заключване и 
                        специалните приспособления, могат да повишат допълнително нивото на защита на входната врата.</p>
                    </p>
                </p>
            </div>

            <hr></hr>

            <div>
                <p className="w3-padding">
                    <h2>Богат избор на цветове за индивидуално оформление</h2>
                    Можете да избирате между 40 различни декора за вашите врати. Палитрата обхваща универсални цветове, декорации с вид на 
                    дърво, повърхности с перлена структура или с вид на алуминий. 
                    Цветовете са групирани по архитектурни стилове, за да отговорят на всеки индивидуален вкус.
                </p>
            </div>

            <div className="w3-row-padding w3-margin-top w3-margin-bottom">
                <ul className="w3-ul w3-card-4 w3-hoverable">
                    <QuickLink text="Вижте повече" textSmall="за цветовата програма на Декьонинк" imgSrc={col_icon} href="/col_pvc" />
                </ul>
            </div>

        </div>
    )
}

export default Vrati_Arcade