import React from 'react'
import QuickLink from '../../components/QuickLink'
import Accordion from '../../components/Accordion'
import col_icon from '../../images/icons/col_icon.png'

import alum_house from '../../images/oblitz/alum_house_resize.jpg'
import alum_scheme from '../../images/oblitz/alum_scheme.jpg'
import alum_white from '../../images/oblitz/alum_white.jpg'

import Oblitz_Alum_Montage from './oblitz_alum_montage'


function Oblitz_Alum() {

    const item = [
        {
          text: "Монтаж",
          content: Oblitz_Alum_Montage
        }
      ]

    return (

        <div>

            <div className="w3-cell-row">
                <img src={alum_house} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <div className="w3-cell w3-padding w3-mobile">
                    <h2>Облицовки за фасади и покриви от алуминий</h2>
                    <p>Фасадната облицовка от алуминий придава изисканост на сградата, като осигурява и необходимата защита от атмосферните 
                        условия. Профилите са налични в огромно разнообразие от цветове, давайки свобода при избора на дизайна на вашата 
                        сграда. Алуминиевите профили могат да се съчетават и с фасадни елементи от Twinson.</p>
                    <p>Фасадните облицовки от алуминий са надеждна защита срещу влага от външната среда и от конденз. Устойчиви са на 
                        неблагоприятни метеорологични условия и на замърсяване на въздуха. Изключително лесни са за почистване.</p>
                </div>
            </div>

            <hr></hr>

            <div className="w3-cell-row">
                <img src={alum_scheme} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
                <img src={alum_white} className="w3-cell w3-padding w3-margin-top w3-mobile"></img>
            
                <div className="w3-cell w3-padding w3-mobile">
                    <h2>Характеристики</h2>
                    <ul>
                        <li>Профили с дължина от 3 метра и ширина от 167 мм</li>
                        <li>Отговарят на изискванията за клас А по реакция на огън</li>
                        <li>Вградена система за вентилация: циркулиращият въздух отвежда влагата и топлината</li>
                        <li>Налични във всички цветове по RAL системата и във всички специални цветове</li>
                        <li>Завършващи профили от елоксиран алуминий в черно, сребърно и кафяво</li>
                        <li>Изключително здрави – конструкция с укрепващи метални свързващи части</li>
                        <li>Система за присъединяване тип зъб-глеб – успоредно подреждане на фасадните елементи от само себе си</li>
                        <li>Бърз и лесен монтаж със специално разработени закрепващи клипсове</li>
                        <li>Фасадните елементи могат да бъдат използвани като част от системата за изолация или самостоятелно като облицовка</li>
                    </ul>

                    <hr></hr>

                    <h2>Различни възможности за използване</h2>
                    <p>Поради малката им дебелина могат да се използват както за новопостроени, така и за стари сгради. Подходящи са за 
                        покриване на външни стени, еркерни прозорци, капандури  и капчуци, като могат да се приложат или като част от 
                        система за изолация, или самостоятелно като облицовка.</p>

                    <hr></hr>
                    <Accordion sectionArr={item}></Accordion>
                </div>
            </div>

        </div>
    )
}

export default Oblitz_Alum