import React from 'react'
import ModalBox from '../components/ModalBox'
import tecnocor_cover from '../images/pluz/tecnocor_cover.jpg'
import Pluz_Tecnocor from './text/pluz_tecnocor'

function ComponentTextTecnocor() {
  return (
    <div>
      <h4>TECNOCOR>2</h4>
      <h6><i>Най-елегантният начин да свържете вътрешното пространство на вашия дом с външния свят</i></h6>
      <p style={{fontSize: "10pt"}}> 
        Системата Tecnocor>2 съчетава предимствата на плъзгащата система със свойствата на PVC, 
        за да постигне отлична функционалност, звуко- и топлоизолация.
      </p>
    </div>
  )
}

function Pluzgane() {

    return (
      <div className="w3-padding">
        <h1>Плъзгане</h1>   

        <ModalBox id="tecnocor" imgPath={tecnocor_cover} textComp={ComponentTextTecnocor} text="Tecnocor>2" component={Pluz_Tecnocor}/>   
      </div>
    );
  }
  
  export default Pluzgane;