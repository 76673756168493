import React from 'react'
import ModalBox from '../components/ModalBox'
import Povd_Hebeschiebetur from './text/povd_hebeschiebetur'
import hebesch_ex from '../images/povd/hebesch_ex.jpg'

function ComponentTextHebeschiebetur() {
  return (
    <div>
      <h4>HEBESCHIEBETÜR</h4>
      <h6><i>Свържете интериора на дома с пространството отвън</i></h6>
      <p style={{fontSize: "10pt"}}> 
        Подходяща при особено големи размери на вратите за тераси. Лесно и удобно отваряне, отлична изолация, стабилност и здравина.
      </p>
    </div>
  )
}

function Povdigane() {

  return (
    <div className="w3-padding">
      <h1>Плъзгане с повдигане</h1>   
      
      <ModalBox id="hebesch" imgPath={hebesch_ex} textComp={ComponentTextHebeschiebetur} text="Hebeschiebetur" component={Povd_Hebeschiebetur}/>       
    </div>
  );
}

export default Povdigane;